<template>

  <div class="map-container" id="mapid">
    <div v-show="loading" class="loading-animation">
      <div class="sk-cube-grid">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
      </div>
    </div>
    <div class="community-feature-switch" v-if="this.map !== null">
      <div :class="{button: true, active: !this.showCommunities}" @click="updateSwitchCommunitiesView(false)">Ganze Region</div>
      <div :class="{button: true, active: this.showCommunities}" @click="updateSwitchCommunitiesView(true)">Gemeindeauswahl</div>
    </div>
    <div class="gauge-position">
      <!-- value needs an offset of +6 due to labels moving the bars -->
      <Co2Gauge :labels="[{ value: 80 + 6, name: '2030' }]" />
      
      </div>
        <div class="gauge-info-box">
          <info-modal>
            Der Balken zeigt an, welchen Anteil erneuerbaren Energien am
            Bruttostrombedarf Sie mit Ihren derzeitigen Einstellungen erreichen.
            Für das Jahr 2030 gibt die Bundesregierung das Ziel von mindestens 65 %
            vor. Für das Jahr 2050 soll dieser Wert mindestens 80 % betragen.
            Anhand der gestrichelten Linie erkennen Sie, wann dieser Wert mit Ihren
            Einstellungen erreicht wird.
          </info-modal>
        </div>
        <div class="battery-storage-gauge-position">
          <gauge :isBatteryStorageGauge="true" :labels="[{value: 17, name: ''}, {value: 60, name: ''}]" />
        </div>
        <div class="gauge-info-box-batteryStorage">
          <info-modal>
            Batteriespeichersysteme werden dafür genutzt, überschüssige Strommengen zwischen zu speichern,
            um diese zu Zeitpunkten erhöhten Strombedarfs nutzen zu können und leisten damit einen großen
            Beitrag zur Autarkie. Unter der Voraussetzung, dass ein hoher Ausbau der erneuerbaren Energien
            erfolgt, steigt der Autarkiegrad proportional zur Erhöhung der Anzahl und somit Kapazität der
            Batteriespeicher. Der Balken zeigt an, wann Batteriespeicher einen geringen, mittleren oder
            hohen Beitrag zur Autarkie leisten.
          </info-modal>
        </div>
    </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import L  from "leaflet"

import Gauge from '@/components/GaugeElement.vue'
import Co2Gauge from '@/components/Co2Gauge.vue'
import InfoModal from '@/components/InfoModal.vue'

import powerPlantService from "@/services/data-service.js";

export default {
  name: 'TheMap',
  components: {
    InfoModal,
    Co2Gauge,
    Gauge,
  },
  props: ['communityKey', 'showCommunities'],
  async mounted() {
    await this.getGeoJson()
    await this.getCommunities()
    this.initMap()
    this.drawFeatures()
  },
  data() {
    return {
      map: null,
      tileLayer: null,
      legend: L.control({ position: "topright" }),

      solarColor: '#f0d26c',
      windColor: '#a3d3de',
      waterColor: '#394866',
      biomassColor: '#91ae58',

      markerLayer: null,
      comLayer: null,
      selectedCom: null,

      comStyleDefault: {
        "opacity": 1,
        "color": '#7f7f7f',
        "fillOpacity": 0.05
      },
      comStyleGrey: {
        "opacity": 1,
        "color": '#494949',
        "fillOpacity": 0.4
      }
    }
  },

  methods: {
    async getGeoJson() {
      try {
        const response = await powerPlantService.getGeoJson();
        this.geoJson = response
      } catch (e) {
        console.log(e)
      }
    },
    async getCommunities() {
      try {
        const response = await powerPlantService.getCommunities();
        this.communities = response
      } catch (e) {
        console.log(e)
      }
    },
    initMap() {
      this.map = L.map('mapid').setView([48.76508, 11.42372], 10);
      this.comLayer = null;
      this.tileLayer = L.tileLayer(
        'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png',
        {
          maxZoom: 18,
          minZoom: 10,
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>',
        }
      )
      this.tileLayer.addTo(this.map)
      this.markerLayer = L.layerGroup().addTo(this.map)
      this.legend.onAdd = function() {
        let div = L.DomUtil.create("div", "legend");
        div.style.width = '360px';
        div.style.height = '30px';
        div.style.justifyContent = 'left';
        div.style.alignItems = 'center';
        div.style.display = 'flex';
        div.style.right = '70px';
        div.style.boxShadow = '0 0 15px rgba(0, 0, 0, 0.2)';
        div.style.background = 'rgba(255, 255, 255, 0.8)';
        div.style.color = '#555';
        div.innerHTML += '<i style="background: #91ae58; width: 15px; height: 15px; float: left;margin: 0px 15px 0px 5px;border-radius: 50%;"><span style="margin:0px 15px 15px 20px">Biomasse</span></i>';
        div.innerHTML += '<i style="background: #f0d26c; width: 15px; height: 15px; float: left;margin:0px 35px 0px 55px;border-radius: 50%;"><span style="margin:50px 15px 15px 20px">Solaranlage</span></i>';
        div.innerHTML += '<i style="background: #394866; width: 15px; height: 15px; float: left;margin:0px 55px 0px 45px;border-radius: 50%;"><span style="margin:0px 15px 15px 20px">Wasserkraft</span></i>';
        div.innerHTML += '<i style="background: #a3d3de; width: 15px; height: 15px; float: left;margin:0px 15px 0px 25px;border-radius: 50%;"><span  style="margin:0px 15px 15px 20px">Windkraft</span></i>';
        return div;
      };
      this.legend.addTo(this.map);

      this.comLayer = this.initCommunities()
    },
    initCommunities() {
       // default community style
      const comStyleDefault = this.comStyleDefault
      // style for community while hovering over it
      const comStyleHover = {
        "color": '#4dbcb7',
        "opacity": 0.4,
        "fillOpacity": 0.3,
      }
      // style for community that was clicked last
      const comStyleClicked = {
        "color": '#4dbcb7',
        "opacity": 1,
        "fillOpacity": 0,
      }

      const comJson = this.geoJson
      const coms =  this.communities
      var map = this.map
      var instance = this
      var layer = new L.geoJSON(comJson, {
        weight: 2, // border thickness
        style: comStyleDefault, // initial style
        onEachFeature: function(feature, layer) {
          layer.bindTooltip(Object.keys(coms).find(k => coms[k] == feature.properties.community_key));
          layer.on("mouseout", function() {
            if (instance.selectedCom == null) this.setStyle(comStyleDefault)
            if (this != instance.selectedCom && instance.selectedCom != null) {
              this.setStyle(instance.comStyleGrey)
              layer.bringToBack()
            }
          })
          layer.on("mouseover", function() {
            layer.bringToFront()
            if (this != instance.selectedCom)
              this.setStyle(comStyleHover)
          })
          layer.on("click", function() {
              layer.bringToFront()
              if (instance.selectedCom !== this) {
                instance.$emit('communityKey', feature.properties.community_key) // fire event that communityKey got changed
                instance.clearPowerPlants()
                const zoom = 12 + 0.108 * (1.0/Math.max(feature.properties.height, feature.properties.width))
                map.setView(feature.properties.center, zoom) // 1. param: coordiantes / 2. param: distance/zoom, higher is closer (app starts on 10)
                instance.setGreyStyle()
                this.setStyle(comStyleClicked)
                instance.selectedCom = this
              }
            }
          )
        }
      })
      return layer
    },
    updateSwitchCommunitiesView: function (value) {
      this.$emit('showCommunities', value)
    },
    updateShowCommunities() {
      console.log("uodate show communities")
      if (!this.showCommunities) {
        this.map.removeLayer(this.comLayer)
        this.map.setView([48.76508, 11.42372], 10)
        this.comLayer.setStyle(this.comStyleDefault) // set all coms style to default
      } else {
        this.comLayer.addTo(this.map)
        this.selectedCom = null
      }
    },
    drawFeatures() {
      this.markerLayer.clearLayers()
      this.powerPlants.forEach(f => {
      let circle = L.circle(f.coords, {
          color: this.typeColor(f.type),
          weight: 1,
          fillColor: this.typeColor(f.type),
          fillOpacity: 0.5,
          radius: this.positionOnly ? 100 : f.radius
        })

        circle.bindPopup(f.tooltip)
        circle.on('click', function () {
            this.openPopup();
        });

        circle.addTo(this.markerLayer)
      })
    },
    clearPowerPlants() {
      this.markerLayer.clearLayers()
    },
    setGreyStyle() {
      this.comLayer.setStyle(this.comStyleGrey)
    },
    typeColor(t) {
      if(t == 'solar') return this.solarColor
      if(t == 'solar_potential_open') return this.solarColor
      if(t == 'solar_potential_roof') return this.solarColor
      if(t == 'wind') return this.windColor
      if(t == 'wind_potential') return this.windColor
      if(t == 'water') return this.waterColor
      if(t == 'biomass') return this.biomassColor
      if(t == 'biomass_potential_farming') return this.biomassColor
      if(t == 'biomass_potential_waste') return this.biomassColor
    },

},
  computed: {
    powerPlants() {
      return this.$store.getters.powerPlants || []
    },
    loading() {
      return this.$store.getters.loading
    },
    positionOnly() {
      return this.$store.getters.radiusRepresentation == 'positionOnly'
    },
  },
  watch: {
    powerPlants() {
      this.drawFeatures()
    },
    positionOnly() {
      this.drawFeatures()
    },
     showCommunities: function() {
       this.clearPowerPlants()
       this.updateShowCommunities()
       if(!this.showCommunities) {
         this.$emit('communityKey', "") // remove community key
         if (this.selectedCom == null )this.drawFeatures()
       }
     }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.map-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.loading-animation {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  z-index: 9999;
}

.gauge-position {
  position: absolute;
  left: 10px;
  bottom: 10px;
  height: 300px;
  width: 50px;
  z-index: 9998;
}


.gauge-info-box {
  position: absolute;
  left: 10px;
  bottom: -255px;
  height: 300px;
  width: 50px;
  z-index: 9999;
}

.battery-storage-gauge-position{
  position: absolute;
  right: 10px;
  bottom: 20px;
  height: 220px;
  width: 53px;
  z-index: 9998;
}

.gauge-info-box-batteryStorage {
  position: absolute;
  right: 11px;
  bottom: -249px;
  height: 300px;
  width: 50px;
  z-index: 9999;
}
.sk-cube-grid {
  width: 100%;
  height: 100%;
  // margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: $highlight;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

.community-feature-switch {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  background-color: #2D3044;
  padding: 15px 15px;
  border-radius: 100px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
}
.community-feature-switch .button.active {
  background-color: $highlight;
}
</style>
