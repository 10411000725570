<template>
  <div class="wasser-container">
    <div class="start-section">
      <div class="title">
        Wasserkraft
      </div>
    </div>
    <div class="section">
      <div class="section-subtitle">
        Allgemein
      </div>
      <div class="content">
        <p>
          Wasser weist beispielsweise bei Flüssen eine natürliche Bewegung oder
          Strömung auf, die von Wasserkraftwerken zur Stromerzeugung genutzt
          werden kann. Abhängig von der Funktion der Wasserkraftanlagen wird
          zwischen zwei Formen von Wasserkraftwerken unterschieden. Während
          Laufwasserkraftwerke die kontinuierlich fließende Bewegung des Wassers
          nutzen, werden in Speicherwasser- und Pumpspeicherkraftwerken durch
          Talsperren Gewässer aufgestaut und liefern vor allem in Zeiten erhöhten
          Bedarfs Strom. Die Stromerzeugung von Wasserkraftwerken hängt u. a. von
          Parametern wie dem Durchfluss, der Fallhöhe und dem
          Turbinenwirkungsgrad ab.
        </p>
      </div>

      <div class="section-subtitle">
        Zur Region
      </div>
      <div class="content">
        <p>
          Die Nutzung von Wasserkraft hat in Bayern, auf Grund der vielen Flüsse und Bäche mit guten Gefälleverhältnissen und ausreichender Wasserführung, eine lange Tradition. Bis Mitte der 1920er Jahre konnte der bayerische Strombedarf fast ausschließlich durch Wasserkraft gedeckt werden.
        </p>

        <p>
          Durch die günstige Lage mehrerer Gemeinden der Region 10 an der Donau und an kleineren Flüssen, gibt es eine große Anzahl an Wasserkraftanlagen. Der erneuerbare Strom aus Wasserkraft wird regional ausschließlich in Laufwasserkraftwerken erzeugt.
        </p>

        <p>
          In der Region 10 stehen 81 Anlagen, die im Jahr ca. 660.000 MWh Strom erzeugen. Damit könnten bei einem durchschnittlichen jährlichen Stromverbrauch eines Haushalts von 3 MWh, ca. 220.000 Haushalte mit Strom versorgt werden.
        </p>

        <p>
          Die fünf leistungsstärksten Wasserkraftwerke in Bertoldsheim, Bittenbrunn, Bergheim, Ingolstadt und Vohburg a.d. Donau speisen direkt ins Netz der Deutschen Bahn ein und versorgen die Züge mit erneuerbarem Strom.
        </p>

        <img src="~@/assets/Wasserkraft_bestand.png" class="center"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WasserkraftInfo',
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.wasser-container {
  position: relative;
}

.start-section {
  background-image: url('~@/assets/water01.jpg');
}
</style>
