<template>
  <div class="biomasse-container">
    <div class="start-section">
      <div class="title">
        Biomasse
      </div>
    </div>
    <div class="section">
      <div class="section-subtitle">
        Allgemein
      </div>
      <div class="content">
        <p>
          Biomasse im Bereich der Erneuerbaren Energien bezeichnet alle organischen Stoffe pflanzlichen oder tierischen Ursprungs, die als Energieträger genutzt werden. Dabei wird zwischen forstwirtschaftlicher Biomasse (Energieträger: Holz), landwirtschaftlicher Biomasse (Energieträger: Energiepflanzen, die explizit für die energetische Nutzung angebaut werden wie z. B. Mais) sowie Biomasse aus Reststoffen (bezeichnet Biomasse die als „Reststoffe“ in der Landwirtschaft entstehen bzw. Abfall der genutzt werden kann, wie z. B. Altholz, gewerbliche Speiseabfälle, Grüngut, Bioabfall, tierische Exkremente, Klärschlamm und Stroh) unterschieden.
        </p>
        <p>
          Aus flüssiger Biomasse, wie Pflanzenölen oder biogenen Reststoffen, können Bio-Kraftstoffe hergestellt und somit Erdölprodukte (z.B. Benzin oder Diesel) ersetzt werden. Feste Biomasse, wie Holz oder Mais, kann beispielsweise in Biomasseheizkraftwerken bzw. Biogasanlagen zur Strom- und Wärmeerzeugung genutzt werden. Die Stromerzeugung von Biomasseanlagen hängt u. a. von Parametern der eingesetzten Substrate (Art der Biomasse) ab, wie der Gasausbeute oder dem Heizwert, sowie von technischen Parametern der Verbrennungseinheit, wie dem elektrischen Wirkungsgrad.
        </p>
        <p>
          Bei der energetischen Umwandlung von Biomasse wird genauso viel Treibhausgas freigesetzt, wie die Pflanze während ihrem Wachstumsprozess aus der Atmosphäre aufgenommen hat. Man spricht daher davon, dass die energetische Nutzung von Biomasse nahezu klimaneutral ist. Ein weiterer Vorteil bei der Nutzung von Biomasse ist die Stärkung der regionalen Wirtschaftskraft und die Möglichkeit die Energie in großen Mengen zwischenspeichern zu können.
        </p>
      </div>
      <div class="section-subtitle">
        Zur Region:
      </div>
      <div class="content">
        <p>
          In Bayern besitzt die Energiegewinnung aus Biomasse, auf Grund des hohen Waldanteils im Vergleich zu anderen Bundesländern, eine lange Tradition.
        </p>
        <p>
          In der Region 10 stehen 113 Biomasseanlagen (davon 71 Biogasanlagen), die neben Wärme ca. 300.000 MWh Strom im Jahr erzeugen. Damit könnten bei einem durchschnittlichen jährlichen Stromverbrauch eines Haushalts von 3 MWh, ca. 100.000 Haushalte mit Strom versorgt werden.
        </p>
        <img src="~@/assets/biomasse_bestand.png" class="center"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BiomasseInfo',
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.biomasse-container {
  position: relative;
}

.start-section {
  background-image: url('~@/assets/biomasse_title.jpg');
}
</style>
