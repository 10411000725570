<template>
  <div class="main-container">

    <the-header/>

    <div class="start-section">
      <div class="title">
        Energiewende<br/>
        in der Region 10
      </div>
      <div class="tutorial cta" @click="showTutorial = true">Tutorial Starten</div>
      <div class="application cta" @click="$router.push('/application')">Anwendung Starten</div>
      <div class="scroll-me">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
      </div>
    </div>

    <div class="section">
      <div class="section-title">
        Über das Tool
      </div>
      <div class="content">
        <p>
          Mit dem Tool „Energiewende regionalisieren“ können Sie Ihre eigenen
          Einstellungen zur Entwicklung der Energiewende in der Region 10 tätigen. Dabei
          werden Ihnen Optionen zum Ausbau der Erneuerbaren Energien sowie zur
          Entwicklung des Strombedarfs und Einsatz von Sektorkopplung und
          Batteriespeichern gegeben. Die Folgen Ihrer gewählten Einstellungen werden
          Ihnen anhand einer Karte der Region sowie mehreren Grafiken visualisiert. Dabei
          sehen Sie nicht nur Ihre eigene Energiewende-Vision, sondern auch den Vergleich
          zur allgemeinen Meinung der Bevölkerung in der Region.
        </p>

        <p>
          Das Tool „Energiewende regionalisieren“ ist während des Projekts
          „Mensch in Bewegung“ entwickelt worden. „Mensch in Bewegung“ ist ein
          gemeinsames Projekt der Technischen Hochschule Ingolstadt (THI) und der
          Katholischen Universität Eichstätt-Ingolstadt (KU). Welche neuen Ideen
          gibt es für die Mobilität? Wie kann sich die Region 10 nachhaltig
          entwickeln? Wie lässt sich die digitale Transformation in der Lebens-
          und Arbeitswelt gut gestalten? Und wie kann bürgerschaftliches
          Engagement bei den Herausforderungen unserer Zeit helfen? Das sind die
          zentralen Fragen des Projekts. Die THI ist mit ihrem technischen und
          wirtschaftlichen Schwerpunkt eng mit der Industrie und Wirtschaft der
          Region verbunden. Die KU bringt durch ihre geisteswissenschaftliche
          Ausrichtung insbesondere soziale, gesellschaftliche und nachhaltige
          Aspekte ein.
        </p>
        <p>
          Zur Webseite von <a href="https://mensch-in-bewegung.bayern"> Mensch in Bewegung </a>
        </p>
      </div>
    </div>


    <div class="tech" id="tech">
      <div class="section-title">
        Technik
      </div>
      <div class="content">
        <div class="tech-gallery">
          <div class="tech-item"
            :style="{ backgroundImage: 'url(' + require('@/assets/solar01.jpg' ) + ')' }"
            @click="$router.push('/info/photovoltaik')">
            <div class="label">Photovoltaik</div>
          </div>
          <div class="tech-item"
            :style="{ backgroundImage: 'url(' + require('@/assets/wind01.jpg' ) + ')' }"
            @click="$router.push('/info/windkraft')">
            <div class="label">Windkraft</div>
          </div>
          <div class="tech-item"
            :style="{ backgroundImage: 'url(' + require('@/assets/water01.jpg' ) + ')' }"
            @click="$router.push('/info/wasserkraft')">
            <div class="label">Wasserkraft</div>
          </div>
          <div class="tech-item"
            :style="{ backgroundImage: 'url(' + require('@/assets/biomass01.jpg' ) + ')' }"
            @click="$router.push('/info/biomasse')">
            <div class="label">Biomasse</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showTutorial" class="modal-overlay" @click="showTutorial = false">
      <div class="modal-content tutorial-container" @click.stop>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/E-7brTs_JOU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>

    <partner-logos/>

    <the-footer/>

  </div>
</template>

<script>
import PartnerLogos from '@/components/PartnerLogos.vue'
import TheFooter from "@/components/TheFooter";
import TheHeader from "@/components/TheHeader";

export default {
  name: 'HomePage',
  components: {
    TheHeader,
    TheFooter,
    PartnerLogos,
  },
  data() {
    return {
      showTutorial: false
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.main-container {
  position: relative;
}

.start-section {
  background-image: url(../assets/regen01.jpg);
  position: relative;

  .cta {
    color: white;
    text-transform: uppercase;
    font-size: 30px;
    border-bottom: 1px solid $highlight;
    position: absolute;
    bottom: 100px;
    cursor: pointer;

    &:hover {
      border-bottom: 2px solid $highlight;
    }
  }
  .tutorial {
    left: 150px;
  }
  .application {
    right: 150px;
  }

  .scroll-me {
    position: absolute;
    bottom: 50px;
    left: 50%;
    color: white;
    animation: bounce 2s alternate-reverse infinite ease-in-out;
  }
}

.tech {
  padding: 50px;
}

.tech-gallery {
  display: flex;

  .tech-item {
    flex: 1;
    background-size: cover;
    background-position: center;
    height: 500px;
    position: relative;
    cursor: pointer;

    &:hover {
      box-shadow: 2px 2px 10px $dark;
    }

    .label {
      color: white;
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      padding: 20px;
      text-transform: uppercase;
      font-size: 20px;
      background-color: rgba($dark, 0.6);
      text-align: left;
    }
  }
}

.tutorial-container {
  background-color: $dark;
  padding: 50px;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}
</style>
