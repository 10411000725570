<template>
  <div class="info-container">
    <the-header/>

    <router-view/>

    <the-footer/>
  </div>
</template>

<script>

import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";
export default {
  name: 'InfoContainer',
  components: {
    TheFooter,
    TheHeader
  },
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.info-container {
  position: relative;
}
</style>
