<template>
  <div class="chartEnergyRegion">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>

import Chart from 'chart.js/auto';

export default {
  name: 'chartEnergyRegion',
  props: ['chartEnergyRegion', 'legend', 'showChart', 'title', 'showTitle'],
  components: {
  },
  async mounted() {
    this.init()

    let chartData = Object.values(this.preparedResults(this.chartEnergyRegion));
    if(this.showChart == false) {
      chartData = "empty"
    }
    const chartEnergyRegion = new Chart(this.$refs["chart"].getContext("2d"), {
      type: 'pie',
      data: {
        labels: Object.keys(this.chartEnergyRegion),
        datasets: [{
          label: 'Chart Energy',
          data: chartData,
          backgroundColor: [
              '#AA7DCE',
              '#55DDE0',
              '#7DCFB6',
              '#33658A',
              '#F6AE2D',
          ],
          hoverOffset: 5
        }]
      },
      options: {
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.label + ": " + Math.ceil(tooltipItem.parsed) + "%";
              }
            }
          },
          legend: {
            display: this.legend,
            position: 'bottom',
            align: 'start',
            onClick: function(event, legendItem) { return event, legendItem }
          },
          title: {
            display: this.showTitle,
            text: this.title,
            padding: {
              top: 10,
              bottom: 30
            },
            position: 'bottom'
          }
        }
      }
    });
    chartEnergyRegion;
  },
  data() {
    return {
      token: null,
      results: null,
      chart: null
    }
  },
  methods: {
    init() {},
    preparedResults(data) {
      let arr = [];
      let sumNum = 0;
      for (const key in data) {
        sumNum += data[key];
      }
      for (const [key, value] of Object.entries(data)) {
        arr[key] = (value * 100 / sumNum).toFixed(1)
      }
      return arr;
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
