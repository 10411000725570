<template>
    <div class="weiterfuehrendeLinks-container">
        <div class="start-section">
            <div class="title">
                Weiterführende Links
            </div>
        </div>
        <div class="section">
            <div class="section-subtitle">
                Allgemein
            </div>
            <div class="content">

             <p>
                 Weitere Informationen zum Projekt Mensch in Bewegung finden Sie hier:
             </p>
                 <a class="link-name-ref" href=" https://mensch-in-bewegung.bayern"/>

              <p>
                 Für Informationen zu Aktivitäten der Technischen Hochschule Ingolstadt besuchen Sie unsere Homepage unter:
              </p>
                 <a class="link-name-ref" href="https://www.thi.de/"/>

            </div>

          <div class="section-subtitle">
            <p>
              Bildungsmaterial für den Unterricht
            </p>
          </div>
          <div class="content">
            <p>
              <a class="" target="_blank" href="https://www2.thi.de/mib/erg/story.html">https://www2.thi.de/mib/erg/story.html</a> <br/><br/>
              <a class="" target="_blank" href="/static/Leitfaden-Energiewendetool-im–Unterricht.pdf">Leitfaden Energiewendetool im Unterricht.pdf</a>
            </p>
          </div>

            <div class="section-subtitle">
                <p>
                    Nachhaltige Studiengänge an der Technischen Hochschule Ingolstadt:
                </p>
                <p>
                    Energiesysteme und Erneuerbare Energien (B. Eng., deutsch/englisch, M.Sc englisch)
                </p>
            </div>
            <div class="content">
                <p>
                    Energiesysteme und Erneuerbare Energien (B. Eng., deutsch/englisch, M.Sc englisch)
                    Du stellst Dir Fragen nach einer sicheren, klimaschonenden, nachhaltigen, aber auch preiswerten Energieversorgung?
                    Du willst bei der Energiewende dabei sein und umfangreiche Veränderungen in der Erzeugung, Verteilung und beim
                    Energieverbrauch anstoßen? Im Studiengang Energiesysteme und erneuerbare Energien beschäftigst Du Dich gezielt mit der
                    Lösung dieser Fragen. Der Studienablauf bietet Dir Aspekte von der klimaschonenden und nachhaltigen Erzeugung, der
                    intelligenten Verteilung und der efﬁzienten Speicherung bis hin zur Versorgung etablierter und neuer Verbraucher im
                    Mobilitäts- sowie im Wärmebereich.
                    https://www.thi.de/maschinenbau/studiengaenge/energiesysteme-und-erneuerbare-energien-beng
                    Energiesysteme und Erneuerbare Energien (B. Eng., deutsch/englisch, M.Sc englisch)
                    Du stellst Dir Fragen nach einer sicheren, klimaschonenden, nachhaltigen, aber auch preiswerten Energieversorgung?
                    Du willst bei der Energiewende dabei sein und umfangreiche Veränderungen in der Erzeugung, Verteilung und beim Energieverbrauch anstoßen?
                    Im Studiengang Energiesysteme und erneuerbare Energien beschäftigst Du Dich gezielt mit der Lösung dieser Fragen.
                    Der Studienablauf bietet Dir Aspekte von der klimaschonenden und nachhaltigen Erzeugung, der intelligenten Verteilung und der
                    efﬁzienten Speicherung bis hin zur Versorgung etablierter und neuer Verbraucher im Mobilitäts- sowie im Wärmebereich.
                </p>
                <p>
                    <a class="link-name-ref" href="https://www.thi.de/maschinenbau/studiengaenge/energiesysteme-und-erneuerbare-energien-beng"/>
                </p>
            </div>

            <div class="section-subtitle">
                <p>
                    Elektrotechnik und Elektromobilität (B. Eng.)
                </p>
            </div>
            <div class="content">
                <p>
                    Du willst mit Elektrotechnik die Mobilität der Zukunft gestalten? Know-how im Zukunfts-feld
                    und Energiespeicher erwerben, das sich längst nicht nur auf Autos und Fahrräder beschränkt,
                    sondern in der Bahntechnik, Fördertechnik, in Robotern und zunehmend in der Medizintechnik
                    eingesetzt wird? Durch eine praxisorientierte Lehre werden Dir an der THI Kenntnisse und Fähigkeiten
                    für eine eigenverantwortliche Ingenieurstätigkeit und anwendungsbezogen das nötige Spezialwissen
                    vermittelt. Ein intensiver Querbezug zu anderen Anwendungsfeldern sichert Deine breite Einsetzbarkeit
                    als Bachelor in verschiedenen Industriezweigen.
                </p>
                <p>
                    <a class="link-name-ref" href="https://www.thi.de/elektro-und-informationstechnik/studiengaenge/elektrotechnik-und-elektromobilitaet-beng"/>
                </p>
            </div>

            <div class="section-subtitle">
                <p>
                    Nachhaltigkeits- und Umweltmanagement (B.Sc.)
                </p>
            </div>
            <div class="content">
                <p>
                    Umwelt und Nachhaltigkeit sind Dir wichtig. Du willst dazu Deinen Beitrag leisten – und mit Deinem Studium lernen,
                    wie Du das in Deinem zukünftigen Beruf umsetzen kannst. Du willst wissen, wie sich ehrgeizige Klimaziele und
                    soziale Verantwortung realisieren lassen – und wie das in Wirtschaft und Gesellschaft funktionieren kann.
                    Du weißt, dass eine nachhaltige Entwicklung gemanagt werden muss. Dazu braucht es Know-how. Betriebswirtschaftliche
                    Kenntnisse spielen dabei eine wichtige Rolle – und natürlich geht es auch um Technologie. Mit dem Bachelor für
                    Nachhaltigkeits- und Umweltmanagement gestaltest Du eine lebenswerte Zukunft. Du übernimmst Verantwortung in
                    Unternehmen, im Finanz- oder Beratungssektor, in öffentlichen Einrichtungen oder internationalen Organisationen
                    oder Du treibst Deine eigene nachhaltige Geschäftsidee voran. Das alles bietet Dir das Studium in Neuburg auf
                    einem smarten Campus, der nach klimafreundlichen und energetischen Gesichtspunkten vor deinen Augen wächst.
                    Werde mit Deinem Studium Pionier in einem innovativen, bayernweit einmaligen Angebot.
                </p>
                <p>
                    <a class="link-name-ref" href="https://www.thi.de/campus-neuburg/studiengang/nachhaltigkeits-und-umweltmanagement-bsc"/>
                </p>
            </div>

            <div class="section-subtitle">
                <p>
                    Wirtschaftsingenieurwesen – Bau (B. Eng.)
                </p>
            </div>
            <div class="content">
                <p>
                    Interessierst Du Dich für das Thema Bauen – und wie das auch ökologisch und nachhaltig geht?
                    Willst Du mit Deiner Arbeit später unsere Welt ein Stück besser bauen – zum Beispiel mit nachhaltigen Baustoffen,
                    unter Nutzung der Erdwärme oder mit regionaler Baulogistik? Mit dem Studiengang Wirtschaftsingenieurwesen – Bau kannst
                    Du in Neuburg in einen ingenieur-wissenschaftlichen Bachelor starten, der in seiner Ausrichtung einmalig in Bayern ist.
                    Er kombiniert Kerninhalte des Bauingenieurwesens mit wirtschaftswissenschaftlichen Inhalten, und setzt bewusst auf Nachhaltigkeit.
                    Er ermöglicht Dir ein breites Wissens- und Anwendungsspektrum vor allem im Bereich des umweltgerechten und nachhaltigen Bauingenieur-wesens.
                    Am innovativen Campusgelände bist du vor Ort dabei, wenn ganzheitliche und nachhaltige Lösungen bei Entwurf, Planung,
                    Projektierung und Realisierung von Bauvorhaben erarbeitet werden. Dein Studium – mit grünen Zukunftsaussichten.
                </p>
                <p>
                    <a class="link-name-ref" href="https://www.thi.de/campus-neuburg/studiengang/wirtschaftsingenieurwesen-bau-beng"/>
                </p>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "WeiterfuerendeLinks",
        data() {
            return {
                key: "value"
            }
        },
        methods: {
            name() {
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '~@/assets/shared.scss';

    .weiterfuehrendeLinks-container {
        position: relative;
    }

    .start-section {
        background-image: url('~@/assets/weiterfuehrendeLinks_title.jpg');
    }

    .link-name-ref::after {
        content: attr(href);
    }

</style>
