const backendURL =
  process.env.VUE_APP_BACKEND_URL;

class StatisticsService {

  async getResults(age, gender, region, job,signal) {
    var url = new URL(backendURL + "/data/statistics");
    var params = {
      age, gender, region, job
    };
    Object.keys(params).forEach(key => {
      if (params[key] !== "" && params[key] !== null) {
        url.searchParams.append(key, params[key])
      }
    });
    return fetch(url, { signal }).then(response => {
      if (response.status !== 200) {
        return response.status
      } else {
        return response.json()
      }
    });
  }
}

export default new StatisticsService();
