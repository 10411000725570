<template>
  <div class="settings-container">
    <div>
      <div class="logo">
        <img src="~@/assets/logos/thi_logo_b_w_transparent.png" />
      </div>
      <div class="back" @click="$router.push('/')">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="position:relative;top:5px;"><polygon points="19 20 9 12 19 4 19 20"></polygon><line x1="5" y1="19" x2="5" y2="5"></line></svg>
        zurück zur Webseite
      </div>
    </div>
    <div >
      <div class="settings">
        <div class="settings-item">
          <div class="trigger" @click="existingFacilitiesExpanded = !existingFacilitiesExpanded">
            Bestandsanlagen
            <div class="expand-icon" :class="{'expanded': existingFacilitiesExpanded }">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
            </div>
          </div>
          <div v-if="existingFacilitiesExpanded" class="expanded-section">

            <div class="existing-faculties">
              Photovoltaik (Dach)
              <span style="float:right;">
                <toggle
                 v-model="existingPhotovoltaikRoof"/>
              </span>
            </div>

            <div class="existing-faculties">
              Photovoltaik (Freifläche)
              <span style="float:right;">
                <toggle
                 v-model="existingPhotovoltaikOpenSpace"/>
              </span>
            </div>


            <div class="existing-faculties">
              Windkraft
              <span style="float:right;">
                <toggle v-model="existingWindkraft"/>
               </span>
            </div>
            <div class="existing-faculties">
              Biomasse <span style="float:right;"><toggle v-model="existingBiomasse"/></span>
            </div>
            <div class="existing-faculties">
              Wasserkraft <span style="float:right;"><toggle v-model="existingWasserkraft"/></span>
            </div>
          </div>
        </div>

        <div class="settings-item">
          <div class="trigger" @click="powerGenerationExpanded = !powerGenerationExpanded">
            Stromerzeugung
            <div class="expand-icon" :class="{'expanded': powerGenerationExpanded }">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
            </div>
          </div>
          <div v-if="powerGenerationExpanded" class="expanded-section">
            <div class="expanded-section-item">
              Photovoltaik (Dachanlagen)
              <info-modal>
                Mit diesem Schieberegler können Sie das Potenzial von
                Solarmodulen, die auf Hausdächer montiert werden, einstellen. Das
                maximal einstellbare Potenzial ergibt sich aus der Annahme, dass
                sich maximal 25 % der Dachflächen von Wohngebäuden und maximal 50
                % der Dachflächen von Industriegebäuden für die Installation von
                Photovoltaik-Anlagen eignen. Weitere Informationen finden Sie in
                der Dokumentation.
                <br>
                <br>
                Aus Datenschutzgründen werden Anlagen &lt;30 kWp und Anlagen die
                durch Erhöhung des Potentials hinzukommen auf den Mittelpunkt der
                Postleitzahl aggregiert.
                </info-modal>
              <div>
                <div v-if="expertMode" class="sliderScale">
                  <div class="slider-options">
                    <div class="slider-option">
                      {{ solarRoofBase | megawatt }}
                    </div>
                    <div class="slider-option-fat">
                          <span class="slider-value description"> {{ powerPhotovoltaikRoof }}% |</span>
                      {{  energy["planned_power_solar_roof"] | megawatt }} {{ mw }}
                    </div>
                    <div class="slider-option">
                      {{ energy['potential_power_solar_roof'] | megawatt }}
                    </div>
                  </div>
                </div>
                <div v-if="!expertMode" class="slider-value">
                  <div class="slider-percentage-value">
                    <span> {{ powerPhotovoltaikRoof }}%</span>
                  </div>
                </div>
                <input type="range" v-model.number="powerPhotovoltaikRoof" min="0" max="100" step="10"/>
              </div>
            </div>
            <div class="expanded-section-item">
              <div @click="powerSolarExpanded = !powerSolarExpanded">
                Photovoltaik (Freiflächen)
                <info-modal>
                  Freiflächenanlagen werden nur auf Flächen, auf denen die
                  Anlagen durch das Erneuerbare-Energie-Gesetz (EEG) gefördert
                  werden, platziert (u. a. Ackerland und Grünland in
                  benachteiligten Gebieten, Seitenrandstreifen von Autobahnen und
                  Bahnstrecken, Konversionsflächen). Dabei werden der Naturschutz
                  und weitere Abstandsregelungen eingehalten (mehr Informationen
                  dazu in der Dokumentation).
                </info-modal>
                <div class="expand-icon" :class="{'expanded': powerSolarExpanded }">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                </div>
              </div>
            </div>
            <div>
              <div v-if="expertMode" class="sliderScale">
                <div class="slider-options">
                  <div class="slider-option">
                    {{ solarOpenBase | megawatt}}
                  </div>
                  <div>
                    <span class="slider-value description"> {{ powerPhotovoltaikField }}% |</span>
                    <span class="slider-option-fat">   {{  solarOpenBase + energy["planned_power_solar_open"] | megawatt}} {{ mw }}</span>
                  </div>
                  <div class="slider-option">
                    {{ solarOpenBase + energy['potential_power_solar_open'] | megawatt}}
                  </div>
                </div>
              </div>
              <div v-if="!expertMode" class="slider-value">
                <div class="slider-percentage-value">
                  <span> {{ powerPhotovoltaikField }}%</span>
                </div>
              </div>
              <input type="range" v-model.number="powerPhotovoltaikField" min="0" max="100" step="1"/>
            </div>
            <div v-if="powerSolarExpanded" class="power-windkraft-expanded">
              <div class="toggle-item">
                Alle Flächen freigeben
                <info-modal>
                  Beim Errichten von Freiflächenanlagen auf Ackerflächen werden diese Flächen in der Regel für landwirtschaftliche Interessen unbrauchbar. Deshalb werden im Allgemeinen lediglich 1 % der gesamten Ackerfläche für die Errichtung von Freiflächenanlagen als raumverträglich ausgewiesen. Aktivieren Sie die Option, dass „alle potentiellen Flächen freigegeben“ werden sollen, werden Freiflächenanlagen auf mehr als 1 % der gesamten Ackerfläche errichtet und der Schieberegler für „Biomasse aus Anbau“ gesperrt. Dies folgt aus der Annahme, dass für „Biomasse aus Anbau“ die gleichen Ackerflächen wie für Freiflächenanlagen genutzt werden.
                </info-modal>
                <span style="float:right;"><toggle v-model="solarAdditionalAreas"/></span>
              </div>
            </div>

            <div class="expanded-section-item" style="cursor:pointer;">

            <div @click="powerWindkraftExpanded = !powerWindkraftExpanded">
              Windkraft
              <span>
                ({{ energy["number_wind_turbines"] }} Anlagen)
              </span>
              <info-modal>
                Potentielle Windkraftanlagen werden nur auf gesetzlich erlaubten Flächen platziert. Als Referenzanlage wird für alle potentiellen Windkraftanlagen eine Anlage mit 3,3 MW Nennleistung, einer Nabenhöhe von 148 m sowie einem Rotordurchmesser von 130 m angenommen. In den Grundeinstellungen wir die 10-H-Regelung beachtet und Windkraftanlagen auch auf Waldflächen platziert. Der Naturpark Altmühltal wird als Potenzialfläche ausgeschlossen. Durch Öffnen der erweiterten Optionen können Sie diese Grundeinstellungen verändern.
              </info-modal>
              <div class="expand-icon" :class="{'expanded': powerWindkraftExpanded }">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
              </div>
            </div>

            <div v-if="expertMode" class="sliderScale" style="margin-top: 4px">
              <div class="slider-options">
                <div class="slider-option">
                   {{ windBase | megawatt }}
                </div>
                <div class="slider-option-fat">
                  <span class="slider-value description"> {{ powerWindkraft }}% |</span>
                  {{  windBase + energy["planned_power_wind"] | megawatt}} {{ mw }}
                </div>
                <div class="slider-option">
                  {{ (energy['potential_power_wind']) | megawatt}}
                </div>
              </div>
            </div>
              <div v-if="!expertMode" class="slider-value">
                <div class="slider-percentage-value-with-padding">
                  <span> {{ powerWindkraft }}%</span>
                </div>
              </div>
            <input type="range" v-model.number="powerWindkraft"  min="0" max="100"/>
            <div v-if="powerWindkraftExpanded" class="power-windkraft-expanded">
              <div class="description">
                Entfernung Anlage zu Wohngebäuden
                <info-modal>
                  Für die Entfernung einer potentiellen Windkraftanlage kann
                  entweder ein Mindestabstand von 600 m gemäß „TA Lärm“
                  eingestellt, ein Abstand von 1000 m, wie in anderen
                  Bundesländern üblich vorgegeben oder die in Bayern geltende
                  10-H-Regelung ausgewählt werden. Bei der 10-H-Regelung müssen
                  potentielle Windkraftanlagen den 10-fachen Abstand Ihrer
                  Gesamthöhe (Nabenhöhe + Rotorradius) zu Siedlungsgebäuden
                  einhalten.
                  <br>
                  <br>
                  Der Abstand von Windkraftanlagen zu Siedlungsgebieten kann in Bayern auch weniger als die in der 10-H-Regelung ausgewiesenen Höhe betragen, in dem in einem Bürgerentscheid dafür gestimmt wird. Deshalb lässt sich mit dieser Option der Abstand variieren.
                </info-modal>
              </div>
              <div class="distance">
                <div class="slider-options">
                  <div class="slider-option">600m</div>
                  <div class="slider-option">1000m</div>
                  <div class="slider-option">10H</div>
                </div>
                <input type="range" v-model.number="powerWindkraftDistance" value="0" min="0" max="2"/>
              </div>
              <div class="toggle-item">
                Wald
                <info-modal>
                  Gesetzlich dürfen Windkraftanlagen Wald aufgestellt werden, sofern keine anderen Einschränkungen vorherrschen. Hier wird die Option geboten, Anlagen im zu vermeiden. Da durch diese Einstellungen weniger Anlagenstandorte zur Verfügung stehen, wird das Gesamtpotenzial für Windkraft insgesamt verringert.
                </info-modal>
                <span style="float:right;"><toggle v-model="powerWindkraftWald"/></span>
              </div>
              <div class="toggle-item">
                Naturpark Altmühltal
                <info-modal>
                  Gesetzlich dürfen Windkraftanlagen im Naturpark Altmühltal (siehe Zonierungskonzept Naturpark Altmühltal) aufgestellt werden, sofern keine anderen Einschränkungen vorherrschen. Hier wird die Option geboten, Anlagen im Naturpark Altmühltal zu vermeiden. Da durch diese Einstellungen weniger Anlagenstandorte zur Verfügung stehen, wird das Gesamtpotenzial für Windkraft insgesamt verringert.
                </info-modal>
                <span style="float:right;"><toggle v-model="powerWindkraftNaturpark"/></span>
              </div>
            </div>
          </div>
          <div class="expanded-section-item">
            Biomasse (Forst- und Reststoffe)
            <info-modal>
              Biomasse im Bereich der Erneuerbaren Energien bezeichnet alle organischen Stoffe pflanzlichen oder tierischen Ursprungs, die als Energieträger genutzt werden. Dabei wird zwischen Biomasse aus Reststoffen und Biomasse aus Energiepflanzen unterschieden.<br/>
              Biomasse aus Forstwirtschaft verwendet Holz als Energieträger, welches nicht bereits in der Industrie oder energetisch genutzt wird. Das maximal einstellbare Potential beeinträchtigt nicht den Waldaufwuchs.
              Biomasse aus Reststoffe bezeichnet Biomasse die als „Reststoffe“ in der Landwirtschaft entstehen bzw. Abfall der genutzt werden kann. Darunter fallen u. a. Altholz, Gewerbliche Speiseabfälle, Grüngut, Bioabfall, Weingärten, Holz, Tierische Exkremente, Straßenbegleitgrün und Stroh.
            </info-modal>
            <input type="range" v-model.number="powerBiomassReststoffe" min="0" max="100" step="10"/>
            <div v-if="!expertMode" class="slider-value">
              <div class="slider-percentage-value-with-padding-left">
                <span> {{ powerBiomassReststoffe }}%</span>
              </div>
            </div>
            <div v-if="expertMode" class="sliderScale">
              <div class="slider-options">
                <div class="slider-option">
                  {{ biomassWasteBase | megawatt3}}
                </div>
                <div class="slider-option-fat">
                  <span class="slider-value description"> {{ powerBiomassReststoffe }}% |</span>
                  {{  biomassWasteBase + energy["planned_power_biomass_waste"] | megawatt3}} {{ mw }}
                </div>
                <div class="slider-option">
                  {{ biomassWasteBase + energy["potential_power_biomass_waste"] | megawatt3}}
                </div>
              </div>
            </div>


            </div>
            <div class="expanded-section-item" :class="{'disabled': solarAdditionalAreas}">
              Biomasse (Anbau)
              <info-modal>
                Biomasse im Bereich der Erneuerbaren Energien bezeichnet alle organischen Stoffe pflanzlichen oder tierischen Ursprungs, die als Energieträger genutzt werden. Dabei wird zwischen Biomasse aus Reststoffen und Biomasse aus Energiepflanzen unterschieden.<br/>
                Mit Biomasse aus Anbau werden Energieträger bezeichnet, die explizit für die Biomassenutzung angebaut werden, wie z. B. Mais (Energiepflanzen). In diesem Tool werden die Bodennutzungsarten der Ackerflächen nicht verändert. Auch die Nutzung der Anbauprodukte in der Lebensmittelversorgung wird nicht berücksichtigt. Wenn bei den Einstellungen zu „Photovoltaik (Freiflächen)“ die Option „Alle Flächen freigeben“ aktiviert wird, wird der Schieberegler für Biomasse aus Anbau gesperrt, da die potentiellen Biomasseanbauflächen durch Photovoltaik eingenommen werden.<br/>
                Bei Anlagen, die durch erhöhen des Potenzials von Biomasse aus Anbau hinzukommen, handelt es sich vorwiegend um Biogasanlagen.
              </info-modal>
              <input type="range" v-model.number="powerBiomassAnbau" min="0" max="100" step="10" :disabled="solarAdditionalAreas"/>
              <div v-if="!expertMode" class="slider-value">
                <div class="slider-percentage-value-with-padding-left">
                  <span> {{ powerBiomassAnbau }}%</span>
                </div>
              </div>
              <div v-if="expertMode" class="sliderScale">
                <div class="slider-options">
                  <div class="slider-option">
                    {{ biomassFarmingBase| megawatt3 }}
                  </div>
                  <div class="slider-option-fat">
                    <span class="slider-value description"> {{ powerBiomassAnbau }}% |</span>
                    {{  biomassFarmingBase + energy["planned_power_biomass_farming"] | megawatt3 }} {{ mw }}
                  </div>
                  <div class="slider-option">
                    {{ biomassFarmingBase + energy['potential_power_biomass_farming'] | megawatt3 }}
                  </div>
                </div>
              </div>

            </div>
            <div class="expanded-section-item">
              Wasserkraft
              <info-modal>
                Das Potenzial der Donau zur Nutzung durch Wasserkraftwerke ist größtenteils ausgeschöpft (Potenzialstudie E.ON), somit ist der Bau neuer Wasserkraftanlagen ausgeschlossen. Durch Modernisierung oder Erweiterung (Nachrüstung) bestehender Anlagen mit weiteren Turbinen oder Nebenkraftwerken kann dennoch eine geringe Potenzialsteigerung von maximal 10 % erreicht werden.
              </info-modal>
              <div class="description">
                Modernisierung- und Nachrüstungspotenzial
              </div>
              <div  class="sliderScale">
                <div v-if="expertMode" class="slider-options">
                  <div class="slider-option">
                    {{ hydroBase | megawatt3 }}
                  </div>
                  <div class="slider-option-fat">
                    <span class="slider-value description"> {{ powerWasserkraft }}% |</span>
                    <!--
                    Alte Version mit Daten vom Backend:
                    {{  hydroBase + energy["planned_power_hydro"] | megawatt3}} {{ mw }}

                    Bei der Wasserkraft gab es einen "Bug", dass eine Erhöhung des konkreten Energiewerts nur alle 20%
                    passiert ist. Es liegt daran, dass es nur 5 große Wasserwerke gibt und bei jeder Erhöhung der
                    Wasserkraft nur vollständige Kraftwerke bzw. deren potentielle Leistung aufaddiert werden.
                    Bei 10% kommt eins der großen Kraftwerke hinzu, bei 20% reicht es auch noch aus.
                    Es wurde entschieden, dass hier einfach ein prozentualer Wert berechnet wird und die Werte vom
                    Backend (erstmal) ignoriert werden.
                    -->
                    {{ powerWasserkraft ? (hydroBase + (energy["potential_power_hydro"] - hydroBase) / 100 * powerWasserkraft) : hydroBase | megawatt3 }} {{ mw }}
                  </div>
                  <div class="slider-option">
                    {{ energy['potential_power_hydro'] | megawatt3 }}
                  </div>
                </div>
              </div>
              <div v-if="!expertMode" class="slider-value">
                <div class="slider-percentage-value-with-padding">
                  <span> {{ powerWasserkraft }}%</span>
                </div>
              </div>
              <input type="range" v-model.number="powerWasserkraft" min="0" max="100" step="10"/>
            </div>
          </div>
        </div>

        <div class="settings-item">
          <div class="trigger" @click="powerRequirementsExpanded = !powerRequirementsExpanded">
            Strombedarf
            <div class="expand-icon" :class="{'expanded': powerRequirementsExpanded }">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
            </div>
          </div>
          <div v-if="powerRequirementsExpanded" class="expanded-section">
            <div class="expanded-section-item">
              Haushalte
              <info-modal>
                Dieser Schieberegler zeigt den derzeitigen jährlichen Strombedarf aller Haushalte in der Region 10 an. Es kann eingestellt werden, ob sich dieser ausgehend vom Status Quo in Zukunft erhöht oder verringert.
              </info-modal>
              <div class="slider-value description">
                Status Quo
                <span v-if="powerRequirementsHouseholds != 0">+ {{ powerRequirementsHouseholds }}%</span>
                <span v-if="expertMode">
                  | {{ energyDemand.households | gigawatt }}  {{gwh}}
                </span>
              </div>
              <input type="range" v-model.number="powerRequirementsHouseholds" min="-50" max="50" step="10"/>
            </div>
            <div class="expanded-section-item">
              Gewerbe, Handel, Dienstleistungen (GHD)
              <info-modal>
                Dieser Schieberegler zeigt den derzeitigen jährlichen Strombedarf der Gewerbe-, Handel- und Dienstleistungsbetriebe in der Region 10 an. Es kann eingestellt werden, ob sich dieser ausgehend vom Status Quo in Zukunft erhöht oder verringert.
              </info-modal>
              <div class="slider-value description">
                Status Quo
                <span v-if="powerRequirementsGHD != 0"> + {{ powerRequirementsGHD }}%</span>
                <span v-if="expertMode">
                  | {{ energyDemand.ghd | gigawatt }}  {{gwh}}
                </span>
              </div>
              <input type="range" v-model.number="powerRequirementsGHD" min="-50" max="50" step="10"/>
            </div>
            <div class="expanded-section-item">
              Industrie
              <info-modal>
                Dieser Schieberegler zeigt den derzeitigen jährlichen Strombedarf der Industrie in der Region 10 an. Es kann eingestellt werden, ob sich dieser ausgehend vom Status Quo in Zukunft erhöht oder verringert.
              </info-modal>
              <div class="slider-value description">
                Status Quo
                <span v-if="powerRequirementsIndustrie != 0"> + {{ powerRequirementsIndustrie }}%</span>
                <span v-if="expertMode">
                  | {{ energyDemand.industry | gigawatt }}  {{gwh}}
                </span>
              </div>
              <input type="range" v-model.number="powerRequirementsIndustrie" min="-50" max="50" step="10"/>
            </div>

        </div>

        <div class="settings-item">
          <div class="trigger" @click="newPowerRequirementsExpanded = !newPowerRequirementsExpanded">
            Strombedarf (neu)
            <div class="expand-icon" :class="{'expanded': newPowerRequirementsExpanded }">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
            </div>
          </div>
          <div v-if="newPowerRequirementsExpanded" class="expanded-section">
            <div class="expanded-section-item">
              Heizen mit Strom (Wärmepumpe)
              <info-modal>
                Unter diesem Schieberegler kann eingestellt werden wie viele konventionelle Heizsysteme in Wohngebäuden zukünftig mit Wärmepumpen ersetzt werden. Ausgangslage ist der derzeitige Bestand an Wärmepumpen.
              </info-modal>
              <div class="slider-value description">
                <span> {{ powerRequirementsHeizen }}%</span>
                <span> aller Gebäude</span>
                <span v-if="expertMode">
                  | {{ energyDemand.heating | gigawatt }}  {{ gwh }}
                </span>
              </div>
              <input type="range" v-model.number="powerRequirementsHeizen" min="0" max="100" step="10"/>
            </div>
            <div class="expanded-section-item">
              Heizenergiebedarfsentwicklung
              <info-modal>
                Ausgehend vom Status Quo des Heizenergiebedarfs kann eingestellt werden, ob sich dieser in Zukunft erhöht (z. B. durch Kühlungssysteme in Neubauten) oder verringert (z. B. durch Sanierungsmaßnahmen).
              </info-modal>
              <div class="slider-options description">
                <div class="slider-option">-25%</div>
                <div
                class="slider-option">{{ heatingDevelopment }}%</div>
                <div class="slider-option">25%</div>
              </div>
              <input type="range" v-model.number="powerRequirementsHeizenBedarfsentwicklung" min="0.75" max="1.25" step="0.01"/>
            </div>
            <div class="expanded-section-item">
              E-Mobilität
              <info-modal>
                Hier kann eingestellt werden wie viele Fahrzeuge mit
                Verbrennungsmotoren mit Elektro-Fahrzeugen ersetzt werden.
                Ausgehend vom derzeitigen Bestand an E-Fahrzeugen werden im
                Maximalfall alle jetzigen zugelassenen Fahrzeuge in der Region 10
                durch E-Fahrzeuge ausgetauscht. Als Referenzfahrzeug wird ein
                E-Fahrzeug mit durchschnittlich 17,5 kWh/100 km Strombedarf und
                einer jährlichen Fahrleistung von 13.500 km verwendet.
              </info-modal>
              <div class="slider-value description">
                <span> {{ powerRequirementsMobilitaet }}%</span>
                <span> möglicher zusätzlicher Fahrzeuge</span>
                <span v-if="expertMode">
                  | {{ energyDemand.mobility | gigawatt }}  {{gwh}}
                </span>
              </div>
              <input type="range" v-model.number="powerRequirementsMobilitaet" min="0" max="100" step="10"/>
            </div>
            <div class="expanded-section-item">
              Mobilitätsbedarfsentwicklung
              <info-modal>
                Ausgehend vom Status Quo des Strombedarfs durch E-Mobilität kann eingestellt werden, ob sich dieser in Zukunft erhöht (z. B. durch höhere jährliche Fahrleistung) oder verringert (z. B. durch effizientere Fahrzeuge)
              </info-modal>
              <div class="slider-options description">
                <div class="slider-option">-25%</div>
                <div class="slider-option">
                  {{mobilityDevelopment}}%
                </div>
                <div class="slider-option">+25%</div>
              </div>
              <input type="range" v-model.number="powerRequirementsMobilitaetBedarfentwicklung" min="0.75" max="1.25" step="0.01"/>
            </div>
          </div>
        </div>
      </div>


        <div class="settings-item">
          <div class="trigger" style="cursor:default;">
            Batteriespeicher
            <info-modal>
              Batteriespeichersysteme können u. a. dazu genutzt werden, überschüssige Strommengen zwischen zu speichern, um diese zu Zeitpunkten erhöhten Strombedarfs nutzen zu können und damit den Autarkiegrad der Region 10 zu erhöhen. Im privaten Bereich werden sie in Kombination mit Photovoltaik-Anlagen verwendet (Heimspeicher). Für das Potenzial wird von einer durchschnittlichen Speichergröße mit 3,25 kW Leistung und 6,5 kWh Kapazität ausgegangen. Werden nahezu alle Wohngebäude mit einem Batteriespeicher ausgestattet, ergibt sich das maximal einstellbare Potenzial. Um dieses Potenzial erreichen zu können, müssen nach dem aktuellen Preis pro kWh Speicherkapazität (ca. 1200 €) insgesamt 960 Millionen Euro aufgewendet werden.
              Der Einfluss der Batteriespeicher wird mit in die Berechnung aufgenommen, wenn Sie die Auswertung starten.
            </info-modal>
          </div>
          <div class="expanded-section-item">
            <div class="slider-value description">
                <span> {{ batteryStorage }}%</span>
                <span> möglicher zusätzlicher Speicher</span>
                <span v-if="expertMode">
                  | {{ batteryBase + energy.planned_battery_storage | megawatt }}  {{mw}}
                </span>
              </div>
          </div>
          <input type="range" v-model.number="batteryStorage" min="0" max="100" step="1"/>
        </div>

        <div class="settings-item">
          <div class="trigger" @click="configurationExpanded = !configurationExpanded">
            Einstellungen
            <div class="expand-icon" :class="{'expanded': configurationExpanded }">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
            </div>
          </div>
          <div v-if="configurationExpanded" class="expanded-section">
            <div class="expanded-section-item">
              Expertenmodus
              <info-modal>
                Im Expertenmodus können Sie die Einheiten der Optionen sehen und in welcher Größenordnung Bestand- und Potenzial bei den jeweiligen Schiebereglern verändert werden.
              </info-modal>
              <span style="float:right;">
                <toggle  v-model="expertMode"/>
              </span>
            </div>
            <div v-if="expertMode" class="expanded-section-item">
              Basiswerte
              <info-modal>
                Hier können Sie konfigurieren ob im Expertenmodus die Zahlenwerte vom Bestand oder vom Zubau ausgehen.
              </info-modal>
              <span style="float:right;">
                <toggle  v-model="withBase"/>
              </span>
            </div>

            <div class="expanded-section-item">
              Nur Standorte
              <info-modal>
                Mit dieser Funktion wird die Größe der Anlagendarstellung nicht anhand der Leistung skaliert, sondern nur Standorte der Erneuerbaren Energie Anlagen angezeigt.
              </info-modal>
              <span style="float:right;">
                <toggle v-model="radiusRepresentation"/>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!showCommunities" class="start-evaluation">
        <div class="button" @click="showDemographicsOverlay = true">Auswertung starten</div>
      </div>
    </div>

    <div v-if="showCommunities" class="community-detail">
      <div v-if="communityKey === '' || communityKey === undefined || communityKey === null">
        <p class="select-community-text">Für den Blick in die Gemeinde bitte eine Gemeinde auf der Karte auswählen</p>
      </div>
      <div v-else>
        <div class="community-detail-data">
          <p>Aktuelle Erzeugung:</p>
          <div class="community-detail-data-chart" v-if="energyFeedInData !== null">
            <chart-energy title="Eingestelltes Szenario" :showTitle="false" :showChart="true" :legend="false" v-bind:chartEnergy="energyFeedInData" :colors="colors"/>
          </div>
        </div>

        <div class="community-detail-data">
          <p>Aktueller Bestand:</p>
          <chart-energy-bars v-bind:chartEnergyFeedIn="energyCurrentData" :labels="['Aktuell']" :colors="colors" :customTooltipScale="'GWh'"/>
        </div>

        <div class="community-detail-data">
          <p>Zusätzlich mögliches Potential:</p>
          <chart-energy-bars v-bind:chartEnergyFeedIn="energyMaxPotentialData" :labels="['Potential']" :colors="colors" :customTooltipScale="'GWh'"/>
        </div>

        <div class="community-detail-data">
          <p>Aktuelle Einstellung:</p>
          <chart-energy-bars v-bind:chartEnergyFeedIn="energyPlannedData" :labels="['Einstellung']" :colors="colors" :customTooltipScale="'GWh'"/>
        </div>
      </div>
    </div>

    <div v-if="showDemographicsOverlay" class="modal-overlay" @click="showDemographicsOverlay = false">
      <div class="modal-content">
        <demographics @close="showDemographicsOverlay = false"/>
      </div>
    </div>

  </div>
</template>

<script>
import Demographics from '@/components/DemographicsModal.vue'
import InfoModal from '@/components/InfoModal.vue'
import Toggle from '@/components/ToggleElement.vue'
import _ from 'lodash'
import ChartEnergy from "./ResultsPage/ChartEnergy";
import ChartEnergyBars from "./ResultsPage/ChartEnergyBars";


export default {
  name: 'EnergySettings',
  components: {
    ChartEnergyBars,
    ChartEnergy,
    Demographics,
    InfoModal,
    Toggle
  },
  props: ['communityKey','showCommunities'],
  created() {
      this.$store.dispatch('updatePlants', this.queryParameters)
      this.$store.dispatch('getCommunities')

  },
  data() {
    return {
      existingFacilitiesExpanded: false,
      existingPhotovoltaikRoof: true,
      existingPhotovoltaikOpenSpace: true,
      existingWindkraft: true,
      existingBiomasse: true,
      existingWasserkraft: true,

      powerGenerationExpanded: false,
      powerPhotovoltaikRoof: 0,
      powerPhotovoltaikField: 0,
      powerWindkraft: 0,
      powerBiomassReststoffe: 0,
      powerBiomassAnbau: 0,
      powerWasserkraft: 0,

      powerWindkraftExpanded: false,
      powerSolarExpanded: false,
      solarAdditionalAreas: false,
      powerWindkraftDistance: 2, // 0 = 600m, 1 = 1000m, 2 = 10H
      powerWindkraftWald: true,
      powerWindkraftNaturpark: false,

      powerRequirementsExpanded: false,
      powerRequirementsHouseholds: 0,
      powerRequirementsGHD: 0,
      powerRequirementsIndustrie: 0,

      newPowerRequirementsExpanded: false,
      powerRequirementsHeizen: 0,
      powerRequirementsHeizenBedarfsentwicklung: 1,
      powerRequirementsMobilitaet: 0,
      powerRequirementsMobilitaetBedarfentwicklung: 1,

      batteryStorage: 0,

      configurationExpanded: false,
      expertMode: false,

      showDemographicsOverlay: false,
      withBase: true,
      colors: [
        'rgb(145, 174, 88)',
        'rgb(240, 210, 108)',
        'rgb(7, 72, 102)',
        'rgb(163, 211, 222)',
      ]
    }
  },
  methods: {
    // emitSettings() {
    //   console.dir("emitting Settings")
    // },

    updatePlants: _.debounce(function() {
      this.$store.dispatch('updatePlants', this.queryParameters)
    }, 500)
  },
  computed: {
    energyFeedInData() {
      if (this.$store.state.energyData.energy_analysis.community_regionalization !== undefined) {
        let data = this.$store.state.energyData.energy_analysis.community_regionalization
        return {
          "Biomasse": parseFloat(data["current_bm"]) || 0,
          "Solaranlage": parseFloat(data["current_pv"]) || 0,
          "Wasserkraft": parseFloat(data["current_wk"]) || 0,
          "Windkraft": parseFloat(data["current_wka"]) || 0
        }
      }
      return null
    },
    energyCurrentData() {
      function  convertCommunityValues(value) {
        if (value ==="") {
          return 0
        } else {
          return (parseInt(100 * (parseFloat(value) / (1000000))) / 100)
        }
      }
      if (this.$store.state.energyData.energy_analysis.community_regionalization !== undefined) {
        let data = this.$store.state.energyData.energy_analysis.community_regionalization
        return {
          "Biomasse": [
            convertCommunityValues(data["current_bm"]),
          ],
          "Solaranlage": [
            convertCommunityValues(data["current_pv"]),
          ],
          "Wasserkraft": [
            convertCommunityValues(data["current_wk"]),
          ],
          "Windkraft": [
            convertCommunityValues(data["current_wka"]),
          ]
        }
      }
      return null
    },
    energyMaxPotentialData() {
      function convertCommunityValues(value) {
        if (value ==="") {
          return 0
        } else {
          return (parseInt(100 * (parseFloat(value) / (1000000))) / 100)
        }
      }

      if (this.$store.state.energyData.energy_analysis.community_regionalization !== undefined) {
        let data = this.$store.state.energyData.energy_analysis.community_regionalization
        return {
          "Biomasse": [
            convertCommunityValues(data["pot_anbau"]) + convertCommunityValues(data["pot_forst/rest"])
          ],
          "Solaranlage": [
            convertCommunityValues(data["pot_pvd"]) + convertCommunityValues(data["pot_pvf"])
          ],
          "Wasserkraft": [
            convertCommunityValues(data["pot_wk"])
          ],
          "Windkraft": [
            convertCommunityValues(data["pot_wka"])
          ]
        }
      }
      return null
    },
    energyPlannedData() {
      function convertCommunityValues(value) {
        if (value ==="") {
          return 0
        } else {
          return (parseInt(100 * (parseFloat(value) / (1000000))) / 100)
        }
      }
      function convertPlannedValues(value) {
        if (value ==="") {
          return 0
        } else {
          return (parseInt(100 * (parseFloat(value) / (1000))) / 100)
        }
      }

      if (this.$store.state.energyData.energy_analysis !== undefined) {
        let data = this.$store.state.energyData.energy_analysis
        return {
          "Biomasse": [
            convertCommunityValues(data.community_regionalization["current_bm"])  + convertPlannedValues(data["planned_power_biomass_farming"]) + convertPlannedValues(data["planned_power_biomass_waste"])
          ],
          "Solaranlage": [
            convertCommunityValues(data.community_regionalization["current_pv"]) + convertPlannedValues(data["planned_power_solar_roof"]) + convertPlannedValues(data["planned_power_solar_open"])
          ],
          "Wasserkraft": [
            convertCommunityValues(data.community_regionalization["current_wk"]) + convertPlannedValues(data["planned_power_hydro"])
          ],
          "Windkraft": [
            convertCommunityValues(data.community_regionalization["current_wka"]) + convertPlannedValues(data["planned_power_wind"])
          ]
        }
      }
      return null
    },
    solarRoofBase() {
      return this.withBase ? this.energy['installed_power_solar_roof']  : 0.0
    },
    solarOpenBase() {
      return this.withBase ? this.energy['installed_power_solar_open']  : 0.0
    },
    windBase() {
      return this.withBase ? this.energy['installed_power_wind']  : 0.0
    },
    biomassWasteBase() {
      return this.withBase ? this.energy['installed_power_biomass_waste']  : 0.0
    },
    biomassFarmingBase() {
      return this.withBase ? this.energy['installed_power_biomass_farming']  : 0.0
    },
    hydroBase() {
      return this.withBase ? this.energy['installed_power_hydro']  : 0.0
    },
    batteryBase() {
      return this.withBase ? this.energy['installed_battery_storage']  : 0.0
    },
    distanceToDwellings() {
      if (this.powerWindkraftDistance == 0)
        return 600
      if (this.powerWindkraftDistance == 1)
        return 1000
      if (this.powerWindkraftDistance == 2)
        return 2130
      return 0
    },
    gw() {
      return this.expertMode ? "GW" : ""
    },
    gwh() {
      return this.expertMode ? "GWh" : ""
    },
    mw() {
      return this.expertMode ? "MW" : ""
    },
    energy(){
      return this.$store.getters.energyAnalysis
    },
    energyDemand(){
      return this.$store.getters.energyDemand
    },
    heatingDevelopment(){
      let percentage = (this.powerRequirementsHeizenBedarfsentwicklung -1) * 100
      return percentage.toFixed(0)
    },
    mobilityDevelopment(){
      let percentage = (this.powerRequirementsMobilitaetBedarfentwicklung -1) * 100
      return percentage.toFixed(0)
    },
    allData() {
      return this.existingPhotovoltaikRoof +
            this.existingPhotovoltaikOpenSpace +
            this.existingWindkraft +
            this.existingBiomasse +
            this.existingWasserkraft +
            this.powerPhotovoltaikRoof +
            this.powerPhotovoltaikField +
            this.powerWindkraft +
            this.powerBiomassReststoffe +
            this.powerBiomassAnbau +
            this.powerWasserkraft +
            this.powerWindkraftDistance +
            this.powerWindkraftWald +
            this.powerWindkraftNaturpark +
            this.powerRequirementsHouseholds +
            this.powerRequirementsGHD +
            this.powerRequirementsIndustrie +
            this.powerRequirementsHeizen +
            this.powerRequirementsHeizenBedarfsentwicklung +
            this.powerRequirementsMobilitaet +
            this.powerRequirementsMobilitaetBedarfentwicklung +
            this.batteryStorage
    },
    queryParameters() {
      return {
        "solar": {
          "display_roof": this.existingPhotovoltaikRoof,
          "display_open_space": this.existingPhotovoltaikOpenSpace,
          "power_solar_roof": this.powerPhotovoltaikRoof,
          "power_solar_open": this.powerPhotovoltaikField,
          "area_restricted": !this.solarAdditionalAreas
        },
        "wind": {
          "display": this.existingWindkraft,
          "power": this.powerWindkraft,
          "settings": {
            "distance_to_dwellings": this.distanceToDwellings,
            "forest_allowed": this.powerWindkraftWald,
            "nature_park_allowed": this.powerWindkraftNaturpark,
          }
        },
        "biomass": {
          "display": this.existingBiomasse,
          "power_biomass_waste": this.powerBiomassReststoffe,
          "power_biomass_farming": this.powerBiomassAnbau,
        },
        "hydro": {
          "display": this.existingWasserkraft,
          "power": this.powerWasserkraft,
        },
        "energy_model_parameters": {
          "requirements": {
          "households": this.powerRequirementsHouseholds,
          "ghd": this.powerRequirementsGHD,
          "industry": this.powerRequirementsIndustrie,
          "heating": this.powerRequirementsHeizen,
          "heating_development": this.powerRequirementsHeizenBedarfsentwicklung,
          "mobility": this.powerRequirementsMobilitaet,
          "mobility_development": this.powerRequirementsMobilitaetBedarfentwicklung,
          "battery_storage": this.batteryStorage
          }
        },
        "community_key": this.communityKey
      }
    },
    radiusRepresentation: {
      get: function () {
        return this.$store.getters.radiusRepresentation == 'positionOnly'
      },
      set: function (newValue) {
        let v = newValue ? 'positionOnly' : 'power'
        this.$store.commit('mutate', { prop: 'radiusRepresentation', value: v })
      }
    },
  },
  watch: {
    solarAdditionalAreas() {
      if (this.powerBiomassAnbau != 0 && this.solarAdditionalAreas)
        this.powerBiomassAnbau = 0
      else
        this.$store.dispatch('updatePlants', this.queryParameters)
    },
    allData() {
      this.updatePlants()
    },
    communityKey: function() {
      this.updatePlants()
    }
  },
  filters: {
    gigawatt(kilowatts){
      return (kilowatts / 1000000).toFixed(1)
    },
    gigawatt3(kilowatts){
      return (kilowatts / 1000000).toFixed(3)
    },
    megawatt(kilowatts){
      return (kilowatts / 1000).toFixed(1)
    },
    megawatt3(kilowatts){
      return (kilowatts / 1000).toFixed(1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';
@import '~@/assets/slider.scss';

.settings-container {
  width: 350px;
  height: 100%;
  box-sizing: border-box;
  background-color: $sidebar;
  color: white;
  padding: 15px 30px;
  text-align: left;
  overflow-y: scroll;
  position: relative;

  .settings {
    clear: both;
  }
}

.logo {
  padding: 0px;
  display: inline-block;
  position: relative;
  float: left;
  margin-bottom: 15px;

  img {
    height: 60px;
  }
}

.back {
  padding-top: 15px;
  opacity: 0.5;
  font-size: 12px;
  float: right;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
}

.expand-icon {
  cursor: pointer;
}

.settings-item {
  margin: 40px 0px;
}

.trigger {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}
.expanded-section {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  // border-bottom: 1px solid rgba(white, 0.2);
}

.slider-value {
  text-align: center;
  font-size: 16px;
}

.existing-faculties {
  padding: 3px 0px;
}

.expanded-section-item {
  padding: 5px 0px;
  font-size: 18px;

  .description {
    padding: 3px 0px;
    font-size: 14px;
    color: rgba(white, 0.5);
  }

}

.sliderScale {
  padding: 3px 0px;
  font-size: 14px;
  color: rgba(white, 0.5);
}

.power-windkraft-expanded {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
  border-bottom: 2px solid rgba(white, 0.3);
  margin-bottom: 10px;

  .distance {
    padding: 3px 0px;
  }
  .toggle-item {
    padding: 2px 0px;
  }
}

.slider-options {
  display: flex;
  font-size: 16px;

  .slider-option {
    flex: 1;
    text-align: center;
  }
  .slider-option-fat {
    color: rgba(white, 0.9);
    text-align: center;
    font-weight: bold;
    width: 250px;
  }
  .slider-option:first-child {
    text-align: left;
  }
  .slider-option:last-child {
    text-align: right;
  }
}

.start-evaluation {
  text-align: center;
  margin: 30px 0;
}

.disabled {
  color: grey;
  cursor: not-allowed;
}
  .slider-percentage-value{
    display: flex;
    padding: 3px 8px;
    font-size: 14px;
    color: rgba(white, 0.5);
    justify-content: center;
  }

 .slider-percentage-value-with-padding{
    display: flex;
    padding: 3px 8px 3px 35px;
    font-size: 14px;
    color: rgba(white, 0.5);
    justify-content: center;
  }
 .slider-percentage-value-with-padding-left{
    display: flex;
    padding: 3px 8px 3px 21px;
    font-size: 14px;
    color: rgba(white, 0.5);
    justify-content: center;
  }

 .community-detail {
   display: block;
   width: 100%;
   margin-top: 100px;
 }

 .select-community-text {
   display: block;
   width: 100%;
 }
</style>
