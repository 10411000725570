<template>
  <div>

    <div v-if="(this.results !== null) ? true : false">
      <div class="flex">
        <div class="grid-left">
          <div class="grid-left--content">
            <div class="mini-header">
              <div class="logo"><img src="~@/assets/logos/thi_logo_b_w_transparent.png" alt="Logo" /></div>
              <div class="mini-header-nav">
                <div class="back"><svg data-v-699a7b7b="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="position: relative; top: 5px;"><polygon data-v-699a7b7b="" points="19 20 9 12 19 4 19 20"></polygon><line data-v-699a7b7b="" x1="5" y1="19" x2="5" y2="5"></line></svg> <a href="/application/">zurück zur Anwendung</a></div>
                <div class="back"><svg data-v-699a7b7b="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="position: relative; top: 5px; transform: scaleX(-1);"><polygon data-v-699a7b7b="" points="19 20 9 12 19 4 19 20"></polygon><line data-v-699a7b7b="" x1="5" y1="19" x2="5" y2="5"></line></svg> <a href="/application/statistics">Zur statistischen Auswertung</a></div>
              </div>

            </div>
            <div class="main-headline">Auswertung Ihres eingestellten Energiewende-Szenarios</div>
            <div class="grid-left--subheadline"><p>In der Tabelle sehen Sie nochmal auf einen Blick, welche Einstellungen Sie für Ihre eigene Energiewende-Vision getätigt haben. Ihnen werden die absoluten Werte für Stromerzeugung, Strombedarf und Batteriespeicher sowie der Anteil am Gesamtpotential angezeigt.</p></div>
          </div>
        </div>
        <div class="grid-right">
          <div class="table-headline">Auflistung Ihrer gewählten Einstellung</div>
          <results-table :resultsTableSettings="(this.results != null) ? this.results['user_settings'] : null"/>
        </div>
      </div>

      <div class="flex">
        <div class="grid-left">
          <div class="grid-left--content">
            <div class="grid-left--headline">Resultierende Anlagen aus Ihren Einstellungen</div>
            <div class="grid-left--subheadline"><p>In dieser Grafik sehen Sie einen Kartenausschnitt der Region 10 mit der von Ihnen gewählten Anlagenverteilung erneuerbarer Energien. Durch einen Klick auf den jeweiligen Anlagenstandort erhalten Sie weitere Informationen zur jeweiligen Anlage.</p></div>
          </div>

          <div class="legende">
            <div class="legende--title">Legende</div>
            <div class="legende--content">
              <div class="legende--content-item windkraft">Windkraft</div>
              <div class="legende--content-item wasserkraft">Wasserkraft</div>
              <div class="legende--content-item photovoltaik">Photovoltaik</div>
              <div class="legende--content-item biomasse">Biomasse</div>
            </div>
          </div>
        </div>
        <div class="grid-right">
          <div class="the-map-eval">
            <div class="the-map-eval-class" v-if="(this.results != null)"><the-map-eval :chartGdf="(this.results != null) ? this.results['map_data'] : null"/></div>
          </div>
        </div>
      </div>

      <div class="flex">
        <div class="grid-left">
          <div class="grid-left--content">
            <div class="grid-left--headline">Zusammensetzung der erneuerbaren Stromerzeugung</div>
            <div class="grid-left--headline-sub">in Prozent (%)</div>
            <div class="grid-left--subheadline"><p>Diese Grafik zeigt, wie sich die erneuerbare Stromerzeugung in der Region 10 für Ihre Einstellungen und den Status Quo zusammensetzt. Dabei lässt sich erkennen, wie hoch der jeweilige Anteil der erneuerbaren Energie an der gesamten Stromerzeugung ist. Wenn Sie den Mauszeiger über die einzelnen Bestandteile des Diagramms bewegen, bekommen Sie die jeweilige Prozentzahl angezeigt.</p></div>
          </div>
          <div class="legende">
            <div class="legende--title">Legende</div>
            <div class="legende--content">
              <div class="legende--content-item biomasse">Biomasse</div>
              <div class="legende--content-item pv-dachflaechen">PV Dachflächen</div>
              <div class="legende--content-item wasserkraft">Wasserkraft</div>
              <div class="legende--content-item pv-freiflaechen">PV Freiflächen</div>
              <div class="legende--content-item windkraft">Windkraft</div>
              <div class="legende--content-item sonstige">Sonstige</div>
            </div>
          </div>
        </div>
        <div class="grid-right">
          <div class="chart-energy-data--pie">
            <div class="chart-energy-class" v-if="(this.results != null)"><chart-energy title="Eingestelltes Szenario" :showTitle="true" :showChart="true" :legend="false" :chartEnergy="(this.results != null) ? this.results['feedin_pie']['user'] : null"/></div>
            <div class="chart-energy-class" v-if="(this.results != null)"><chart-energy title="Aktueller Stand (2021)" :showTitle="true" :showChart="true" :legend="false" :chartEnergy="(this.results != null) ? this.results['feedin_pie']['present'] : null"/></div>
          </div>
        </div>
      </div>

      <div class="flex">
        <div class="grid-left">
          <div class="grid-left--content">
            <div class="grid-left--headline">Jährliche Stromerzeugung</div>
            <div class="grid-left--headline-sub">in GWh</div>
            <div class="grid-left--subheadline"><p>Diese Grafik zeigt die Zusammensetzung der erneuerbaren Stromerzeugung der Region 10 in GWh für Ihre Einstellungen. Sie können auf einen Blick erkennen, wie hoch die gesamte Stromerzeugung durch erneuerbare Energien ist. Außerdem wird deutlich wie viel die Stromerzeugung insgesamt und je erneuerbarer Energie im Vergleich zum aktuellen Stand angestiegen ist.</p></div>
          </div>

          <div class="legende">
            <div class="legende--title">Legende</div>
            <div class="legende--content">
              <div class="legende--content-item biomasse">Biomasse</div>
              <div class="legende--content-item pv-dachflaechen">PV Dachflächen</div>
              <div class="legende--content-item wasserkraft">Wasserkraft</div>
              <div class="legende--content-item pv-freiflaechen">PV Freiflächen</div>
              <div class="legende--content-item windkraft">Windkraft</div>
              <div class="legende--content-item sonstige">Sonstige</div>
            </div>
          </div>
        </div>
        <div class="grid-right">
          <div class="chart-energy-data--bar">
            <div class="chart-energy-bar-class" v-if="(this.results != null)"><chart-energy-bar :bars="['Eingestelltes Szenario', 'Aktueller Stand (2021)']" :chartEnergyBarAnnual="(this.results != null) ? this.results['annual_feedin']['user'] : null" :chartEnergyBarFeedIn="(this.results != null) ? this.results['annual_feedin']['present'] : null"/></div>
          </div>
        </div>
      </div>

      <div class="flex">
        <div class="grid-left">
          <div class="grid-left--content">
            <div class="grid-left--headline">Monatliche Stromerzeugung je Erneuerbarer Energieträger</div>
            <div class="grid-left--headline-sub">in GWh</div>
            <div class="grid-left--subheadline"><p>In dieser Grafik sehen Sie die monatliche Stromerzeugung der Erneurbaren Energieträger für Ihr eingestelltes Szenario in GWh. Somit lässt sich der saisonale Wettereinfluss erkennen, der sich vor allem bei Photovoltaik und Windkraft bemerkbar macht. Außerdem sehen Sie die monatliche Stromerzeugung je Erneuerbarer Energieträger und die Differenz der Stromerzeugung im Vergleich der erneuerbaren Energieträger.</p></div>
          </div>

          <div class="legende">
            <div class="legende--title">Legende</div>
            <div class="legende--content">
              <div class="legende--content-item windkraft">Windkraft</div>
              <div class="legende--content-item wasserkraft">Wasserkraft</div>
              <div class="legende--content-item photovoltaik">Photovoltaik</div>
              <div class="legende--content-item biomasse">Biomasse</div>
            </div>
          </div>
        </div>
        <div class="grid-right">
          <div class="chart-energy-data--bars">
            <div class="chart-energy-bar-class" v-if="(this.results != null)"><chart-energy-bars :chartEnergyFeedIn="(this.results != null) ? this.results['monthly_feedin'] : null"/></div>
          </div>
        </div>
      </div>

      <div class="flex">
        <div class="grid-left">
          <div class="grid-left--content">
            <div class="grid-left--headline">Zusammensetzung des Strombedarfs der Region 10</div>
            <div class="grid-left--headline-sub">in Prozent (%)</div>
            <div class="grid-left--subheadline">
              <p>
                Diese Grafik zeigt, wie sich der Strombedarf in der Region 10 für Ihre Einstellungen und den Status Quo zusammensetzt. Dabei lässt sich erkennen, wie hoch der jeweilige Anteil der verschiedenen Sektoren am gesamten Strombedarf ist. Wenn Sie den Mauszeiger über die einzelnen Bestandteile des Diagramms bewegen, bekommen Sie die jeweilige Prozentzahl angezeigt.
              </p>
            </div>
          </div>
          <div class="legende">
            <div class="legende--title">Legende</div>
            <div class="legende--content">
              <div class="legende--content-item waermepumpen">Wärmepumpen</div>
              <div class="legende--content-item e-mobilitaet">E-Mobilität</div>
              <div class="legende--content-item industrie">Industrie</div>
              <div class="legende--content-item ghd">Gewerbe, Handel, Dienstleistungen</div>
              <div class="legende--content-item haushalte">Haushalte</div>
            </div>
          </div>
        </div>
        <div class="grid-right">
          <div class="chart-energy-data--pie">
            <div class="chart-energy-class" v-if="(this.results != null)"><chart-energy-region title="Eingestelltes Szenario" :showTitle="true" :showChart="true" :legend="false" :chartEnergyRegion="(this.results != null) ? this.results['annual_demand']['user'] : null"/></div>
            <div class="chart-energy-class" v-if="(this.results != null)"><chart-energy-region title="Aktueller Stand (2021)" :showTitle="true" :showChart="true" :legend="false" :chartEnergyRegion="(this.results != null) ? this.results['annual_demand']['present'] : null"/></div>
          </div>
        </div>
      </div>

      <div class="flex">
        <div class="grid-left">
          <div class="grid-left--content">
            <div class="grid-left--headline">Jährlicher Strombedarf</div>
            <div class="grid-left--headline-sub">in GWh</div>
            <div class="grid-left--subheadline">
              <p>
                Diese Grafik zeigt die Zusammensetzung des Strombedarfs der Region 10 in GWh für Ihre Einstellungen. Sie können auf einen Blick erkennen, wie hoch der gesamte Strombedarf, der durch die verschiedenen Sektoren verursacht wird, ist. Außerdem wird deutlich wie viel der Strombedarf insgesamt und je Sektor im Vergleich zum aktuellen Stand angestiegen ist.
              </p>
            </div>
          </div>

          <div class="legende">
            <div class="legende--title">Legende</div>
            <div class="legende--content">
              <div class="legende--content-item waermepumpen">Wärmepumpen</div>
              <div class="legende--content-item e-mobilitaet">E-Mobilität</div>
              <div class="legende--content-item industrie">Industrie</div>
              <div class="legende--content-item ghd">Gewerbe, Handel, Dienstleistungen</div>
              <div class="legende--content-item haushalte">Haushalte</div>
            </div>
          </div>
        </div>
        <div class="grid-right">
          <div class="chart-energy-data--bar">
            <div class="chart-energy-bar-class" v-if="(this.results != null)"><chart-energy-bar-region :bars="['Eingestelltes Szenario', 'Aktueller Stand (2021)']" :chartEnergyBarRegionAnnual="(this.results != null) ? this.results['annual_demand']['user'] : null" :chartEnergyBarRegionFeedIn="(this.results != null) ? this.results['annual_demand']['present'] : null"/></div>
          </div>
        </div>
      </div>

      <div class="flex">
        <div class="grid-left">
          <div class="grid-left--content">
            <div class="grid-left--headline">Export, Import & Batterie&shy;speicher in der Region 10</div>
            <div class="grid-left--headline-sub">in GWh</div>
            <div class="grid-left--subheadline">
              <p>
                Diese Grafik visualisiert die jährlich exportierten, importierten sowie gespeicherten Strommengen für Ihre Einstellungen.<br/><br/>
                Bei sehr großen exportierten und importierten Strommengen, sind zusätzliche Stromtrassen für den Transport nötig.<br/><br/>
                Durch Batteriespeicher können Export und Import verringert werden, da ein Anteil der Strommengen zwischengespeichert werden kann.
              </p>
            </div>
          </div>

          <div class="legende">
            <div class="legende--title">Legende</div>
            <div class="legende--content">
              <div class="legende--content-item export">Export</div>
              <div class="legende--content-item import">Import</div>
              <div class="legende--content-item batteriespeicher">Batteriespeicher</div>
            </div>
          </div>
        </div>
        <div class="grid-right">
          <div class="chart-energy-data--bars">
            <div class="chart-energy-bar-class" v-if="(this.results != null)"><chart-energy-bars-region :chartEnergyIeBattery="(this.results != null) ? this.results['ie_battery'] : null"/></div>
          </div>
        </div>
      </div>

      <div class="flex">
        <div class="grid-left">
          <div class="grid-left--content">
            <div class="grid-left--headline">Eigenversorgungsanteil und Autarkiegrad</div>
            <div class="grid-left--headline-sub">in Prozent (%)</div>
            <div class="grid-left--subheadline">
              <p>
                In dieser Grafik können Sie erkennen, ob die Ziele für das Jahr 2030 (80 % bilanzieller Anteil) oder 2050 (Klimaneutralität) mit Ihren Einstellungen erreicht werden.<br/><br/>
                Zusätzlich sehen Sie den Anteil des erneuerbar erzeugten Stroms, der zeitglich durch die Stromverbraucher in der Region genutzt werden kann (Eigenverbrauchsanteil) sowie den Anteil des Stromverbrauchs, der zeitgleich durch den in der Region erneuerbar erzeugten Strom versorgt werden kann (Autarkiegrad).<br/>
                Der Eigenverbrauchsanteil und Autarkiegrad werden dabei mit Verwendung von Batteriespeichern sowie ohne Verwendung von Batteriespeichern berechnet.

              </p>
            </div>
          </div>

          <div class="legende">
            <div class="legende--title">Legende</div>
            <div class="legende--content">
              <div class="legende--content-item bilanziell">Bilanziell</div>
              <div class="legende--content-item eigenversorgungsanteil">Eigenversorgungsanteil</div>
              <div class="legende--content-item eigenversorgungsanteil-ohne-batterie">Eigenversorgungsanteil ohne Battteriespeicher</div>
              <div class="legende--content-item autarkiegrad">Autarkiegrad</div>
              <div class="legende--content-item autarkiegrad-ohne-batterie">Autarkiegrad ohne Batteriespeicher</div>
            </div>
          </div>
        </div>
        <div class="grid-right">
          <div class="chart-energy-data--bars">
            <div class="chart-energy-bar-class" v-if="(this.results != null)"><chart-energy-bars-own :chartEnergyAut="(this.results != null) ? this.results['aut'] : null"/></div>
          </div>
        </div>
      </div>

      <div class="flex">
        <div class="grid-left"></div>
        <div class="grid-right">
          <div class="footer-logo-container">
            <div class="logo-kombi" ><img src="../assets/logos/Kombi_IH_BMBF_GWK.png"/></div>
            <div class="logo-thi"><img  src="../assets/logos/thi_logo_wb_RGB.png"/></div>
            <div class="logo-mib" ><img src="../assets/logos/logo-mib.jpg"/></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="results-loading-container">
      <div class="results-loading-inner">
        <AnimatedLoader/>
        <h2 class="results-loading-headline">Auswertung lädt</h2>
        <p>Einen Moment noch.<br/> Die Auswertung wird gleich geöffnet.</p>
      </div>
    </div>

  </div>
</template>
<script>

import resultsService from "@/services/results-service";
import ResultsTable from "../components/ResultsPage/ResultsTable";
import ChartEnergy from "../components/ResultsPage/ChartEnergy";
import ChartEnergyRegion from "../components/ResultsPage/ChartEnergyRegion";
import ChartEnergyBar from "../components/ResultsPage/ChartEnergyBar";
import ChartEnergyBarRegion from "../components/ResultsPage/ChartEnergyBarRegion";
import ChartEnergyBars from "../components/ResultsPage/ChartEnergyBars";
import ChartEnergyBarsRegion from "../components/ResultsPage/ChartEnergyBarsRegion";
import ChartEnergyBarsOwn from "../components/ResultsPage/ChartEnergyBarsOwn";
import TheMapEval from "@/components/ResultsPage/TheMapEval";
import AnimatedLoader from "../components/Loader";

export default {
  name: 'ResultsPage',
  components: {
    AnimatedLoader,
    TheMapEval,
    ChartEnergyBarsOwn,
    ChartEnergyBarsRegion,
    ChartEnergyBars,
    ChartEnergyBarRegion,
    ChartEnergyBar,
    ChartEnergyRegion,
    ChartEnergy,
    ResultsTable
  },

  async mounted() {
    this.init()
  },
  data() {
    return {
      token: null,
      results: null
    }
  },
  methods: {
    init() {
      this.token = location.search.split('token=')[1]
      this.getResults()
    },
    async getResults() {
      let rq =  await resultsService.getResults(this.token);
      if (rq === 409) {
        this.stillLoading = true
        setTimeout(function () {
          this.getResults()
        }.bind(this),5000)
      } else {
        this.stillLoading = false
        this.results = rq
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  html   {
    box-sizing: border-box;
  }
  .chart-energy-data--pie {
    margin: 0 auto;
    display: flex;
    height: 100%;
    width: 80%;

    .chart-energy-class {
      flex: 1;
      max-width: 50%;
    }
  }

  .resultsTableSettings {
    padding: 0;
  }
  .main-headline {
    font-size: 32px;
    line-height: 39px;
    font-weight: bold;
    text-align: left;
    clear: both;
    //hyphens: auto;
  }
  .mini-header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
  .mini-header .logo {
    float: left;
    width: 50%;
    text-align: left;
  }
  .mini-header .logo img {
    max-width: 85px;
  }
  .mini-header .mini-header-nav {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
  }
  .mini-header .back {
    float: right;
    margin: 5px 0;
  }
  .mini-header .back svg {
    position: relative;
    bottom: 4px;
    color: #808080;
  }
  .mini-header .back a {
    color: #808080;
    text-decoration: none;
    font-size: 11px;
  }
  .mini-header .back a:hover {
    text-decoration: underline;
  }
  .flex {
    display: flex;
    align-items: stretch;
  }
  .flex .grid-left {
    min-width: 220px;
    background-color: #2D3044;
    color: #FFFFFF;
    padding: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media(max-width: 1500px) {
      flex: 1;
    }
    @media(min-width: 1500px) {
      width: 400px;
    }
  }
  .flex .grid-left .legende {
    width: 100%;
    text-align: left;
    font-size: 11px;
    line-height: 13px;
    /*position: absolute;
    bottom: 20px;
    .flex .grid-left .legende {
      position: relative;
      margin-top: 50px;
    }*/
  }
  .flex .grid-left .legende--content {
    border-top: 1px solid rgba(255,255,255,.3);
    border-bottom: 1px solid rgba(255,255,255,.3);
    padding: 25px 0;
    margin-top: 10px;
    text-align: left;
  }
  .flex .grid-left .legende--content .legende--content-item {
    width: calc(50% - 25px);
    display: inline-block;
    margin-bottom: 23px;
    padding-left: 25px;
    position: relative;
  }
  .flex .grid-left .legende--content .legende--content-item:before {
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    display: block;
    left: 0;
    position: absolute;
    top: calc(50% - 10px);
    margin-right: 10px;
  }
  .flex .grid-left .legende--content .legende--content-item.windkraft:before {
    background-color: #A3D3DE;
  }
  .flex .grid-left .legende--content .legende--content-item.wasserkraft:before {
    background-color: #394866;
  }
  .flex .grid-left .legende--content .legende--content-item.photovoltaik:before {
    background-color: #F0D26C;
  }
  .flex .grid-left .legende--content .legende--content-item.biomasse:before {
    background-color: #91AE58;
  }
  .flex .grid-left .legende--content .legende--content-item.pv-dachflaechen:before {
    background-color: #FFCC99;
  }
  .flex .grid-left .legende--content .legende--content-item.pv-freiflaechen:before {
    background-color: #F0D26C;
  }
  .flex .grid-left .legende--content .legende--content-item.sonstige:before {
    background-color: #DB99FF;
  }
  .flex .grid-left .legende--content .legende--content-item {
    &.waermepumpen:before {
      background-color: #F6AE2D;
    }
    &.e-mobilitaet:before {
      background-color: #33658A;
    }
    &.industrie:before {
      background-color: #7DCFB6;
    }
    &.ghd:before {
      background-color: #55DDE0;
    }
    &.haushalte:before {
      background-color: #AA7DCE;
    }
    &.export:before {
      background-color: #42cbf5;
    }
    &.import:before {
      background-color: #f5aa42;
    }
    &.batteriespeicher:before {
      background-color: #5742f5;
    }
    &.bilanziell:before {
      background-color: #84E5CD;
    }
    &.eigenversorgungsanteil:before {
      background-color: #60AED8;
    }
    &.eigenversorgungsanteil-ohne-batterie:before {
      background-color: #88D96F;
    }
    &.autarkiegrad:before {
      background-color: #BDB76B;
    }
    &.autarkiegrad-ohne-batterie:before {
      background-color: #F0E68C;
    }
  }
  .flex .grid-left .grid-left--headline {
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    font-weight: bold;
  }
  .flex .grid-left .grid-left--subheadline {
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    margin-top: 26px;
  }
  .flex .grid-left .grid-left--headline-sub {
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    margin-top: 5px;
  }
  .flex .grid-right {
    width: 66.6666%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
  }
  .flex .grid-right .table-headline {
    text-align: left;
    font-size: 18px;
    line-height: 22px;
    padding: 44px 0 0px;
  }
  .the-map-eval-class {
    margin: 0 -20px;
  }

  .results-loading-container {
    width: 100%;
    height: 100vh;
    background-color: #2D3044;
    display: flex;
    justify-content: center;
    align-items: center;

    .results-loading-inner {
      color: #FFF;

      .loading-animation {
        margin: 0 auto;
      }

      .results-loading-headline {
        font-size: 32px;
        line-height: 39px;
      }

      p {
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
      }

    }
  }

  .footer-logo-container {
    display: flex;
    width: 80%;
    margin: 0 10%;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.logo-kombi {
        flex: 3;
      }

      &.logo-thi, &.logo-mib {
        flex: 1
      }

      img {
        max-width: 100%;
      }
    }

  }
</style>
