const backendURL =
  process.env.VUE_APP_BACKEND_URL;

class ResultsService {

  async getResults(token) {
    var url = new URL(backendURL + "/data/run-energy-model");
    let params = {
      token: token,
    };
    Object.keys(params).forEach(key =>
        url.searchParams.append(key, params[key])
    );
    return fetch(url).then(response => {
      if (response.status !== 200) {
        return response.status
      } else {
        return response.json()
      }
    });
  }
}

export default new ResultsService();
