<template>
  <div class="partner-logos-container">
    <div>
      <img src="../assets/logos/logo01.jpg"/>
    </div>
    <div>
      <img src="../assets/logos/logo-thi.png"/>
    </div>
    <div>
      <img src="../assets/logos/logo-mib.jpg"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartnerLogos',
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
.partner-logos-container {
  display: flex;
  max-height: 150px;
  margin: 30px 0px;

  div {
    flex: 1;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
