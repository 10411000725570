<template>
  <div class="energieeffizienz-container">
    <div class="start-section">
      <div class="title">
        Energieeffizienz
      </div>
    </div>
    <div class="section">
      <div class="section-subtitle">
        Allgmein
      </div>
      <div class="content">
        <p>
        Die nachhaltigste Energie ist die, die gar nicht erst verbraucht wird.
        </p>

        <p>
          Ein wichtiger Aspekt bei der Umsetzung der Energiewende ist folglich
          Energie effizient einzusetzen, um so auch den Bruttostrombedarf
          möglichst zu minimieren.
        </p>

        <p>
          Im eigenen Umfeld kann man Strom durch eine nachhaltigere Lebensweise
          einsparen. In Industrie und Gewerbe kann die Energieeffizienz
          bestimmter Prozesse erhöht werden um Strom einzusparen, aber auch eine
          energieeffiziente Gebäudetechnik kann maßgeblich dazu beitragen, dass
          der Energiebedarf wie auch der Bruttostrombedarf gesenkt wird.
        </p>

        <p>
          Mit dem Begriff Energieeffizienz wird beschrieben, wie gut die verfügbare
          Energie tatsächlich genutzt werden kann, denn Energie kann auf
          vielfältige Weise innerhalb eines Prozesses „verloren“ gehen, z.B. in
          Form von Abwärme in Industrieprozessen, und steht deshalb nicht mehr für
          die Erreichung eines bestimmten Zielzustandes zur Verfügung. Die
          Energieeffizienz ist folglich umso höher, je geringer die Energieverluste
          zum Erreichen des Zielzustandes sind. Betrachtet man beispielsweise die
          Wohnraumbeheizung, so zeigt sich, dass je nach Isolierung eines Hauses
          und der Leistung des Heizkörpers ein unterschiedliches Maß an Energie
          benötigt wird, um die gewünschte Wohnraumtemperatur zu erreichen. Wenn
          die Heizung des Gebäudes zum Beispiel mittels einer Wärmepumpe erfolgt,
          kann durch eine entsprechende energieeffiziente Bauweise und mittels
          Heizungsanlagen mit guten Wirkungsgrad eine Reduktion des Strombedarfs
          erreicht werden.
        </p>

        <p>
          Es zeigt sich, dass durch effizientere Prozesse in Industrie und Gewerbe
          sowie effizientere Geräte und Bauweisen der benötigte Strom- und
          Energiebedarf in der Region10 drastisch gesenkt werden kann.
        </p>

        <p>
          Für das Jahr 2050 hat die Bundesregierung das Ziel erklärt, 50 Prozent
          weniger Primärenergie (Definition nach Umweltbundesamt: „Der
          Primärenergiebedarf bezeichnet den Energiegehalt aller im Inland
          eingesetzten Energieträger. Der Begriff umfasst sogenannte
          Primärenergieträger (z. B. Braun- und Steinkohlen, Mineralöl oder
          Erdgas), die entweder direkt genutzt, oder in sogenannte
          Sekundärenergieträger (z. B. Kohlebriketts, Kraftstoffe, Strom oder
          Fernwärme) umgewandelt werden.“) als im Jahr 2008 zu verbrauchen, den
          Stromverbrauch um 25 Prozent gegenüber 2008 zu senken sowie im
          Gebäudebereich einen nahezu klimaneutralen Gebäudebestand zu erreichen.
          Gebäude verursachen rund 40 Prozent des Endenergieverbrauchs und haben
          auch einen relevanten Anteil am Bruttostrombedarf.  Ein Grund für den
          hohen Energiebedarf liegt darin, dass 75 Prozent des Gebäudebestands vor
          1979 errichtet worden ist, also bevor die 1. Wärmeschutzverordnung in
          Kraft getreten ist, und energieeffiziente bauliche Maßnahmen im Fokus
          standen. Durch geeignete Sanierungsmaßnahmen im Bereich der Isolierung
          und Heiztechnik kann die Energieeffizienz in Gebäuden erhöht werden und
          der Energiebedarf sowie der Bruttostrombedarf maßgeblich gesenkt werden.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EnergieeffizienzInfo',
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.energieeffizienz-container {
  position: relative;
}

.start-section {
  background-image: url('~@/assets/energieeffizienz_title.jpg');
}
</style>
