<template>
  <div class="map-container" id="mapid">
    <div v-show="loading" class="loading-animation">
      <div class="sk-cube-grid">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
      </div>
    </div>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import L from "leaflet"

export default {
  name: 'TheMapEval',
  props: ['chartGdf'],
  async mounted() {
    this.initMap()
    this.drawFeatures()
  },
  data() {
    return {
      map: null,
      tileLayer: null,
      legend: L.control({ position: "topright" }),

      solarColor: '#f0d26c',
      windColor: '#a3d3de',
      waterColor: '#394866',
      biomassColor: '#91ae58',

      markerLayer: null,
    }
  },

  methods: {
    initMap() {
      this.map = L.map('mapid').setView([48.76508, 11.42372], 10);
      this.tileLayer = L.tileLayer(
        'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png',
        {
          maxZoom: 18,
          minZoom: 10,
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>',
        }
      )
      this.tileLayer.addTo(this.map)
      this.markerLayer = L.layerGroup().addTo(this.map)

      this.legend.onAdd = function() {
        let div = L.DomUtil.create("div", "legend");
        div.style.width = '360px';
        div.style.height = '30px';
        div.style.justifyContent = 'left';
        div.style.alignItems = 'center';
        div.style.display = 'flex';
        div.style.right = '70px';
        div.style.boxShadow = '0 0 15px rgba(0, 0, 0, 0.2)';
        div.style.background = 'rgba(255, 255, 255, 0.8)';
        div.style.color = '#555';
        div.innerHTML += '<i style="background: #91ae58; width: 15px; height: 15px; float: left;margin: 0px 15px 0px 5px;border-radius: 50%;"><span style="margin:0px 15px 15px 20px">Biomasse</span></i>';
        div.innerHTML += '<i style="background: #f0d26c; width: 15px; height: 15px; float: left;margin:0px 35px 0px 55px;border-radius: 50%;"><span style="margin:50px 15px 15px 20px">Solaranlage</span></i>';
        div.innerHTML += '<i style="background: #394866; width: 15px; height: 15px; float: left;margin:0px 55px 0px 45px;border-radius: 50%;"><span style="margin:0px 15px 15px 20px">Wasserkraft</span></i>';
        div.innerHTML += '<i style="background: #a3d3de; width: 15px; height: 15px; float: left;margin:0px 15px 0px 25px;border-radius: 50%;"><span  style="margin:0px 15px 15px 20px">Windkraft</span></i>';
        return div;
      };
      this.legend.addTo(this.map);
    },
    drawFeatures() {
      let gdfData = JSON.parse(JSON.stringify(this.chartGdf));
      let fillColor = "#000000";

      gdfData.features.forEach(f => {
        switch(f.properties.type) {
          case 'Biomasse':
            fillColor = this.biomassColor;
            break;
          case 'Photovoltaik':
            fillColor = this.solarColor;
            break;
          case 'Wasserkraft':
            fillColor = this.waterColor;
            break;
          case 'Windkraft':
            fillColor = this.windColor;
            break;
        }
        let circle = L.circle(
            [f.properties.coords[0], f.properties.coords[1]],
            f.properties.radius,
            {
              color:  fillColor,
              weight: 1,
              fillColor: fillColor,
              fillOpacity: .5
            });
        circle.bindPopup(f.properties.tooltip)
        circle.on('mouseclick', function () {
          this.openPopup();
        });
        circle.addTo(this.markerLayer)
      })
    },
    typeColor(t) {
      if(t == 'solar') return this.solarColor
      if(t == 'solar_potential_open') return this.solarColor
      if(t == 'solar_potential_roof') return this.solarColor
      if(t == 'wind') return this.windColor
      if(t == 'wind_potential') return this.windColor
      if(t == 'water') return this.waterColor
      if(t == 'biomass') return this.biomassColor
      if(t == 'biomass_potential_farming') return this.biomassColor
      if(t == 'biomass_potential_waste') return this.biomassColor
    },

},
  computed: {
    powerPlants() {
      return this.$store.getters.powerPlants || []
    },
    loading() {
      return this.$store.getters.loading
    },
    positionOnly() {
      return this.$store.getters.radiusRepresentation == 'positionOnly'
    }
  },
  watch: {
    powerPlants() {
      this.drawFeatures()
    },
    positionOnly() {
      this.drawFeatures()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.map-container {
  width: 100%;
  height: 70vh;
}

.loading-animation {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  z-index: 9999;
}

.sk-cube-grid {
  width: 100%;
  height: 100%;
  // margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: $highlight;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}
</style>
