<template>
  <div class="resultsTableSettings">
    <div class="ResultsTableSettings-table">
    <table>
      <thead>
        <tr>
          <th v-for="(value, resultsTableSetting) in resultsTableSettings" v-bind:key="resultsTableSetting">{{ resultsTableSetting }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="resultsTableSetting in this.preparedResults(resultsTableSettings)" v-bind:key="resultsTableSetting.label">
          <td>{{resultsTableSetting.label}} </td>
          <td>{{resultsTableSetting.value}}</td>
          <td>{{resultsTableSetting.potential}}</td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ResultsTable',
  props: ['resultsTableSettings'],
  components: {
  },
  async mounted() {
    this.init()
  },
  data() {
    return {
      token: null,
      results: null,
    }
  },
  methods: {
    init() {},
    preparedResults(input) {
      let arr = [];
      let i = 0;
      for (let key in input) {
        arr[i] = input[key];
        i += 1;
      }
      let res = []
      if(arr[0]) {
        arr[0].map((obj,index) => {
          res.push({
            label: arr[0][index],
            value: arr[1][index],
            potential: arr[2][index]
          })
        })
      }

      return res;
    },
  },
}
</script>

<style lang="scss" scoped>
  table {
    width: 100%;
    margin: 24px auto 30px;
  }
  table tr td, table tr th {
    padding: 10px 0;
  }
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  .resultsTableSettings {
    padding: 0 20px;
  }
  .resultsTableSettings .ResultsTableSettings-table table, .resultsTableSettings .ResultsTableSettings-table table th, .resultsTableSettings .ResultsTableSettings-table table td {
    border: 1px solid #A1ACD2;
    color: #2D3044;
  }
  .resultsTableSettings .ResultsTableSettings-table table thead {
    background-color: #A1ACD2;
  }
  .resultsTableSettings .ResultsTableSettings-table table thead tr th {
    border: none;
  }
</style>
