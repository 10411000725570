<template>
  <div class="gauge-container">
    <div class="gauge">
      <div class="value" :style="levelStyle">
      </div>
    </div>
    <div v-if="!isBatteryStorageGauge" class="value-tag-container" :style="labelStyle(value)">
      <div class="value-tag">
        <div class="arrow-left"></div>
        {{ gaugeValue }}%
      </div>
    </div>

    <div v-else-if="isBatteryStorageGauge" class="value-tag-container-batteryStorage" :style="labelStyle(value)">
      <div class="value-tag-batteryStorage">
        <div class="arrow-right"></div>
        {{ gaugeValue }}
      </div>
    </div>

    <div v-for="(l, i) in labels" class="label" :key="'gauge-' + i + '-' + l.name" :style="labelStyle(l.value)">
      <div class="label-name">
        {{ l.name }}
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'GaugeElement',
  props: ["labels","isBatteryStorageGauge"],
  methods: {
    labelStyle(v) {
      if (this.isBatteryStorageGauge){
        return 'top: ' + Math.max((100-v), 0) + '%;'
      }
      return 'top: ' + Math.max((100-v), 0) + '%;'
    }
  },
  computed: {
    levelStyle() {
      if (this.isBatteryStorageGauge){
        return 'height: ' + (100-this.value) + '%;'
      }
      return 'height: ' + (100-this.value) + '%;'
    },
    gaugeValue(){
      if (this.isBatteryStorageGauge){
        let batteryValue= ((((this.$store.getters.batteryStorageCoverage)/40000)*10).toFixed(1)-1.3)
          if (batteryValue<18){
            return "Geringer Beitrag zur Autarkie"
          }if (batteryValue<60){
            return "Mittlerer Beitrag zur Autarkie"
          }if (60<= batteryValue){
            return "Hoher Beitrag zur Autarkie"
          }
      }
      return (this.$store.getters.energyCoverage*100).toFixed(0)
    },
    value() {
      let value = this.$store.getters.energyCoverage*100
      let valueBatteryStorage = ((this.$store.getters.batteryStorageCoverage)/40000)
      if(this.isBatteryStorageGauge){
          return ((valueBatteryStorage*10).toFixed(1)-1.3)
      }
      if (value > 100)
        return 100
      else
        return (value).toFixed(0)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.gauge-container {
  position: relative;
  height: 100%;
  // background-color: white;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.gauge {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: $highlight;
  overflow: hidden;
}

.value {
  background-color: $sidebar;
}
.value-tag-container {
  position: absolute;
  left: 100%;
}
.value-tag {
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  left: calc(100% + 11px);
  top: -14px;
  background-color: $highlight;
  padding: 5px 8px;
  border-radius: 3px;
  color: $sidebar;
}

.label {
  position: absolute;
  width: 100%;
  border-bottom: 2px dashed white;
}

.label-name {
  color: white;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 13px solid $highlight;
  position: absolute;
  left: -10px;
  top: 4px;
}

.value-tag-container-batteryStorage {
  position: absolute;
  right: 100%;
}
.value-tag-batteryStorage {
  width: 100px;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  right: calc(100% + 11px);
  top: -30px;
  background-color: $highlight;
  padding: 5px 8px;
  border-radius: 3px;
  color: $sidebar;
}
.arrow-right {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 13px solid $highlight;
  position: absolute;
  right: -10px;
  top: 20px;
}

</style>
