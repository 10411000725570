import { render, staticRenderFns } from "./Photovoltaik.vue?vue&type=template&id=b87c7ffe&scoped=true&"
import script from "./Photovoltaik.vue?vue&type=script&lang=js&"
export * from "./Photovoltaik.vue?vue&type=script&lang=js&"
import style0 from "./Photovoltaik.vue?vue&type=style&index=0&id=b87c7ffe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b87c7ffe",
  null
  
)

export default component.exports