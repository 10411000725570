<template>
  <div class="toggle-container">
    <label class="switch">
      <input type="checkbox" v-model="localValue" @change="$emit('input', localValue)">
      <span class="slider round"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'ToggleElement',
  props: ['value'],
  data() {
    return {
      localValue: this.value
    }
  },
  watch: {
    value() {
      this.localValue = this.value
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.toggle-container {
  display: inline-block;
  position: relative;
  top: 4px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 16px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #35384e;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: white;
}

input:focus + .slider {
  box-shadow: 0 0 1px white;
}

input:checked + .slider:before {
  transform: translateX(24px);
  background-color: #35384e;
}

/* Rounded sliders */
.slider.round {
  border-radius: 16px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
