<template>
<div>
  <div class="application-container">
    <div class="left">
      <region-map v-if="mapLoadingConfirmed" v-on:communityKey="updateCommunityKey($event)" :showCommunities.sync="showCommunities"  v-on:showCommunities="updateShowCommunities($event)"/>
      <div class="map-placeholder" v-else>
        <img src="~@/assets/map_blured.jpg" />
        <div class="infomodal-container">
          <div class="modal-overlay">
            <div class="modal-content content">
              <div class="title">
                Willkommen im Web-Tool Energiewende regionalisieren!
              </div>
              <p>Nutzen Sie dieses Tool, um einen Überblick über das Energiesystem in der Region 10 zu bekommen. Mit dem Tool „Energiewende regionalisieren“ können Sie Ihre eigenen Einstellungen zur Entwicklung der Energiewende in der Region 10 tätigen. Dabei werden Ihnen Optionen zum Ausbau der Erneuerbaren Energien sowie zur Entwicklung des Strombedarfs und Einsatz von Sektorkopplung und Batteriespeichern gegeben. Die Folgen Ihrer gewählten Einstellungen werden Ihnen anhand einer Karte der Region sowie mehreren Grafiken visualisiert.</p>
              <ul>
                <li>Bilde dir selbst deine Meinung zur Energiewende in deiner Region.</li>
                <li>Entwerfe dir eine persönliche Energiewende mit verschiedenen Technologien.</li>
                <li>Lass uns deine Vision für die Region über unser Online-Tool zukommen.</li>
              </ul>
              <p>Viel Spaß beim Ausprobieren!</p>
              <p>Um die Karte im Tool anzeigen zu können werden Daten aus externen Quellen nachgeladen. Für die Inhalte übernehmen wir keine Verantwortung. Stimmen Sie dem Nachladen von Daten zu?</p>
              <div class="consent">
                <label class="container">
                  Ich stimme dem Nachladen von Daten aus externen Quellen zu
                  <input type="checkbox" v-model="consent">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="button-container">
                <div class="button" @click="$router.push('/')">Zurück zur Homepage</div>
                <div class="button" @click="saveConsent()" :disabled="!consent">Zum Tool</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <settings :communityKey.sync="communityKey" :showCommunities.sync="showCommunities"/>
    </div>
  </div>

</div>
</template>

<script>
import RegionMap from '@/components/TheMap.vue'
import Settings from '@/components/EnergySettings.vue'
export default {
  name: 'ApplicationContainer',
  components: {
    RegionMap,
    Settings,
  },
  data() {
    return {
      key: "value",
      mapLoadingConfirmed: false,
      consent: false,
      communityKey: "",
      showCommunities: false
    }
  },
  mounted() {
    if (localStorage.mapLoadingConsent) {
      this.mapLoadingConfirmed = localStorage.mapLoadingConsent;
    }
  },
  methods: {
    saveConsent() {
      this.mapLoadingConfirmed = this.consent;
      localStorage.mapLoadingConsent = this.mapLoadingConfirmed;
    },
    updateCommunityKey: function(key) {
      this.communityKey=key
    },
    updateShowCommunities: function(value) {
      this.showCommunities = value
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.application-container {
  display: flex;
  height: 100vh;
  width: 100vw;
}
.left {
  flex: 1;

  .map-placeholder {
    background-color: #FBF7F1;

    img {
      width: 100%;
      height: 99vh;
    }
  }
}

.infomodal-container {
  display: inline-block;
  font-size: 16px;
  color: white;
  text-transform: none;
  font-weight: normal;
}

.trigger {
  opacity: 0.5;
  transition: 0.1s opacity;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.content {
  background-color: $sidebar;
  padding: 50px;
  cursor: default;
  text-align: justify;
}

.close-icon {
  position: absolute;
  top: -16px;
  right: -16px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(black, 0.4);
  text-align: center;
  cursor: pointer;
}

.title {
  text-transform: uppercase;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 50px;
}

.button-container {
  margin-top: 50px;
  text-align: center;

  .button:first-child {
    margin-right: 20px;
  }
}


.consent {
  text-align: left;
  margin: 40px 0px;
}

</style>
