import { render, staticRenderFns } from "./ChartEnergyBars.vue?vue&type=template&id=a74e9340&scoped=true&"
import script from "./ChartEnergyBars.vue?vue&type=script&lang=js&"
export * from "./ChartEnergyBars.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a74e9340",
  null
  
)

export default component.exports