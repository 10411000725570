<template>
  <div class="politics-container">
    <div class="start-section">
      <div class="title">
        Politische Ziele
      </div>
    </div>
    <div class="section">
      <div class="section-subtitle">
        Allgemein
      </div>
      <div class="content">
        <p>
          Aufbauend auf den 2015 in Paris beschlossenen europäischen Klimaschutzzielen, den Anstieg der Globaltemperatur auf 2°C zu begrenzen, hat die Bundesregierung 2016 das „Klimaschutzprogramm 2050“ erstellt, mit dem Ziel bis 2050 in Deutschland einen Anteil von 80 % erneuerbarer Energien am Bruttostrombedarf zu erreichen. Des Weiteren wurde 2019 das „Klimamaßnahmenpaket 2030“ verabschiedet, durch das die Klimaziele aus dem „Klimaschutzprogramm 2050“ für das Zieljahr 2030 angepasst wurden. Demnach soll 2030 ein Anteil von 65 % erneuerbarer Energien am Bruttostrombedarf angestrebt werden. Im Jahr 2021 wurde mit dem so genannten „Osterpaket“ die größte energiepolitische Gesetzesnovelle seit Jahrzehnten verabschiedet. Ein wichtiger Schwerpunkt sind Änderungen im Erneuerbare-Energien-Gesetz (EEG). Bis 2030 soll der Anteil erneuerbarer Energien auf mindestens 80 Prozent steigen. Bis 2035 soll die Stromerzeugung nahezu treibhausgasneutral erfolgen. Die Ziele sollen den Ausbau von erneuerbaren Energien vorantreiben, zu einer Strombedarfssenkung in allen Sektoren sorgen und somit insgesamt den CO2-Ausstoß verringern. Mit der Änderung des Bundes-Klimaschutzgesetztes (KSG) durch das Klimaschutz Sofortprogramm 2022 wurde außerdem das Ziel der Netto-Treibhausgasneutralität bis 2045 festgeschrieben.        </p>
      </div>
      <div class="section-subtitle">
        Zur Region:
      </div>
      <div class="content">
        <p>
          In Bayern wurde Ende 2019 das „Bayerische Aktionsprogramm Energie“ ins Leben gerufen mit eigenen Zielen für den EE-Ausbau und Steigerung der Energieeffizienz bis 2022. Zudem gibt es viele Gemeinden in der Region 10 mit eigenen Energienutzungs- oder Klimaschutzplänen, die auf den Webseiten der jeweiligen Gemeinden einzusehen sind. Beispielsweise Ernsgaden, Ingolstadt und Neuburg a.d. Donau.         </p>
        <p>
          Für Deutschland und Bayern sind die aktuellen Zahlen zu erneuerbaren Energien und dem Stand der Zielerreichung für das Jahr 2021 in der Tabelle und den Grafiken aufgeführt.         </p>
        <div>
          <img src="~@/assets/webseite_bayern.png" class="center small-width"/>
          <img src="~@/assets/webseite_deutschland.png" class="center small-width"/>
          <img src="~@/assets/ziele_br.png" class="center"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PolitikInfo',
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.politics-container {
  position: relative;
}

.start-section {
  background-image: url('~@/assets/regen01.jpg');
}
</style>
