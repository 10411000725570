<template>
  <div class="chartEnergyBarsRegion">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>

import Chart from 'chart.js/auto';

export default {
  name: 'chartEnergyBarsRegion',
  props: ['chartEnergyIeBattery'],
  components: {
  },
  async mounted() {
    this.init()

    let arrOuter = [];
    let obj = {};
    let colorArr = ['#42cbf5', '#f5aa42', '#5742f5'];

    let arr = [];
    for (const [keyIeBattery, valueIeBattery] of Object.entries(this.chartEnergyIeBattery)) {
      keyIeBattery
      arr.push(valueIeBattery);
    }

    obj = {
      data: arr,
      backgroundColor: colorArr
    }

    arrOuter.push(obj)

    //console.log(arrOuter);

    const chartEnergyBarsRegion = new Chart(this.$refs["chart"].getContext("2d"), {
      type: 'bar',
      data: {
        labels: ['Export', 'Import', 'Batteriespeicher'],
        datasets: arrOuter
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
        },scales: {
          y: {
            ticks: {
              callback: function(value,) {
                return value + " GWh";
              }
            }
          }
        }
      }
    });
    chartEnergyBarsRegion;
  },
  data() {
    return {
      token: null,
      results: null,
      chart: null
    }
  },
  methods: {
    init() {}
  },
}
</script>

<style lang="scss" scoped>

</style>
