<template>
  <div class="battery-container">
    <div class="start-section">
      <div class="title">
        Batteriespeicher
      </div>
    </div>
    <div class="section">
      <div class="section-subtitle">
        Allgemein
      </div>
      <div class="content">

      <p>
        Batteriespeichersysteme werden dafür genutzt, überschüssige Strommengen
        zwischen zu speichern, um diese zu Zeitpunkten erhöhten Strombedarfs
        nutzen zu können. Sie können in Kombination mit Photovoltaik Anlagen
        betrieben werden und den ungenutzten oder überschüssigen Strom aus den
        Mittagsstunden speichern.
      </p>

      <p>
        Es wird zwischen dezentralen (für private Haushalte) und großen
        zentralen Speichern (für Industrie und Gewerbe) unterschieden.
        Batteriespeicher können einen wichtigen Beitrag zur Netzstabilität
        leisten.
      </p>

      <p>
        Ein wichtiger Faktor für den verstärkten Einsatz von Batteriespeichern
        im Energiesystem sind die Anschaffungskosten. Der durchschnittliche
        Kaufpreis für Heimspeicher konnte 2019 im Vergleich zu 2013 von 2.500 €
        pro kWh um über 50 % auf 1.200 € pro kWh gesenkt werden (siehe Grafik).
        Aktuell sinken die Speicherkosten laut einem Bericht der RWTH Aachen
        jährlich um 18 Prozent. Die Attraktivität für private
        Heimspeichersysteme nimmt aufgrund der sinkenden Anschaffungskosten
        sowie durch die Steigung der Endverbraucherstrompreise zu. Je mehr
        Strom im eigenen Haushalt selbst erzeugt und verbraucht wird, desto
        geringer sind die finanziellen Belastungen infolge der stetig
        steigenden Energiekosten. Mit der Nutzung eines Stromspeichers in
        Kombination mit einer Photovoltaikanlage lässt sich die
        Eigenverbrauchsquote und damit die Autarkie auf 50 Prozent bis 90
        Prozent steigern.
      </p>

      <p>
        Der Einsatz von Batteriespeicher hängt u. a. von Parametern wie den
        Anschaffungskosten, der Ladezyklen und der nutzbaren Kapazität sowie
        der technischen Einsatzmöglichkeiten ab.
      </p>

      <img src="~@/assets/batteriespeicher.png" class="center"/>

      </div>

      <div class="section-subtitle">
        Zur Region
      </div>
      <div class="content">
        <p>
          In der Region 10 gibt es derzeit ca. 4072 Batteriespeicher mit einer Gesamtleistung von ca. 36 MW.
        </p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'BatteriespeicherInfo',
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.battery-container {
  position: relative;
}

.start-section {
  background-image: url('~@/assets/batteriespeicher_title.jpg');
}
</style>
