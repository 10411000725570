<template>
  <div class="biomasse-container">
    <div class="start-section">
      <div class="title">
        E-Mobilität
      </div>
    </div>
    <div class="section">
      <div class="section-subtitle">
        Allgemein
      </div>
      <div class="content">
        Der Verkehrssektor ist mit seiner konventionellen Energiebereitstellung mit Kraftstoffen aus Erdöl und Erdgas einer der größten Verursacher von Treibhausgasen wie CO2. Um diesen Ausstoß von Treibhausgasen zu verringern und damit die von der Bundesregierung vereinbarten Klimaziele zu erreichen, sind Maßnahmen nötig, die darauf abzielen einen Verkehrssektor mit geringerem CO2-Ausstoß zu entwickeln. Neben Hybrid-Fahrzeugen und Wasserstoffautos ist es durch E-Fahrzeuge möglich, den CO2-Ausstoß zu minimieren. Während der Fahrt verursachen E-Fahrzeuge keine CO2-Emissionen, allerdings steigen durch E-Mobilität die benötigten Strommengen. Dieser zusätzliche Strombedarf muss im Wesentlichen aus erneuerbaren Energien gedeckt werden, damit der CO2-Ausstoß der E-Fahrzeuge gering gehalten werden kann und diese sich positiv auf die Klimaziele auswirken. Immer mehr Fahrzeughersteller befinden sich im Umbruch und bieten derzeit Fahrzeugmodelle mit Elektromotoren zum Kauf an. In Zukunft werden die angebotenen E-Modelle je Automobilkonzern sowie die Reichweiten der Elektrofahrzeuge noch weiter steigen. Neben erneuerbaren Energien ist für eine erfolgreiche Elektrifizierung des Verkehrssektors auch eine ausreichende Ladeinfrastruktur nötig. Der benötigte Strombedarf für E-Mobilität und des Verkehrssektors hängt u. a. von der Fahrleistung, der Fahrzeugtechnik und von der Fahrzeugeffizienz ab.
      </div>

      <div class="section-subtitle">
      </div>
      <div class="content">
        <p>
          Zur Region Ingolstadt ist die Stadt mit den meisten zugelassenen PKWs mit 707 je 1000 Einwohner in Bayern. Von 103.718 sozialversicherungspflichtigen Beschäftigten pendeln täglich 63.365 nach sowie 19.161 aus Ingolstadt. Ein regionaler Automobilhersteller plant bis 2025 30 neue elektrifizierte Modelle im Portfolio zu listen und rund 800.000 Elektroautos und Plug-In-Hybride zu verkaufen. Das ist kein Einzelphänomen in der Branche. Die Bayerische Nachhaltigkeitsstrategie bestärkt mit einem Maßnahmenpaket eine zeitnahe Markteinführung der Elektromobilität. Gleichzeitig wird die Forschung, die Entwicklung und der Einsatz von regenerativer Energie im Verkehrssektor gefördert. In der Region 10 sind 673 PKW je 1000 Einwohner zugelassen. Bei einer durchschnittlichen jährlichen Fahrleistung von 13.500 km und einem durchschnittlichen Stromverbrauch eines E-Fahrzeuges von 18 kWh/100 km würde sich für die Elektrifizierung des gesamten Individualverkehrs in der Region 10 ein jährlicher Stromverbrauch von ca. 930.000 MWh ergeben. Dies entspricht bei einem durchschnittlichen jährlichen Stromverbrauch eines Haushalts von 3 MWh, dem Stromverbrauch von ca. 310.000 Haushalten.        </p>
        <img src="~@/assets/e-mobilitaet.png" class="center"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobilitaetInfo',
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.biomasse-container {
  position: relative;
}

.start-section {
  background-image: url('~@/assets/emobilitaet_title.jpg');
}
</style>
