const backendURL =
  process.env.VUE_APP_BACKEND_URL;

class PowerPlantService {
  getAllPlants() {
    console.dir("Fetching Power Plants from" + backendURL);
    return fetch(backendURL + "/data/power-plants").then(response =>
      response.json()
    );
  }

  async getCommunities() {
    let url = backendURL + "/data/community_keys";
    let response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      }
    });
    return response.json();
  }

  async getGeoJson() {
    let url = backendURL + "/data/community_geojson"
    let response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      }
    });
    return response.json();
  }

  async queryPlants(energyModelParameters) {
    let url = backendURL + "/data/power-plants";
    let response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(energyModelParameters)
    });
    return response.json();
  }

  getWindPlantsUpdate(power, forest, naturePark, distance) {
    var url = new URL(backendURL + "/data/wind-farms");
    let params = {
      power: power,
      forest: forest,
      naturePark: naturePark,
      distance: distance
    };
    Object.keys(params).forEach(key =>
      url.searchParams.append(key, params[key])
    );
    console.dir("Fetching Power Plants from " + backendURL);
    return fetch(url).then(response => response.json());
  }

  async energyModelRequest(energyModelParameters, userData) {
    let url =  new URL(backendURL + "/data/run-energy-model");
    let payload = {
      energy_model_request: energyModelParameters,
      user_data: userData
    };

    let response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });

    return response;
  }
}

export default new PowerPlantService();
