<template>
  <div class="region10-container">
    <div class="start-section">
      <div class="title">
        Region 10
      </div>
    </div>
    <div class="section">
      <div class="section-subtitle">
        Region 10
      </div>
      <div class="content">
        <p>
          Die Planungsregion 10 im Freistaat Bayern besteht aus den Landkreisen Eichstätt, Pfaffenhofen a. d. Ilm, Neuburg a. d. Donau und der kreisfreien Stadt Ingolstadt. In der Region 10 leben insgesamt 499.344 Einwohner (Stand 31. Dez. 2020) in ca. 228.000 Haushalten auf einer Gesamtfläche von 2.848,38 km².
        </p>

        <p>
          Es handelt sich um eine industriestarke Region mit großen Firmen u. a. in der Automobilindustrie, Luft- und Raumfahrt, sowie mehreren Erdöl-Raffinerien.
        </p>

        <p>
          In der Region 10 wurden 2021 insgesamt ca. 2.038.400 MWh Strom aus erneuerbaren Energien erzeugt. Damit könnten bei einem durchschnittlichen jährlichen Stromverbrauch eines Haushalts von 3 MWh (1 MWh entspricht 1000 kWh), ca. 680.000 Haushalte mit Strom versorgt werden. Den größten Anteil an der erneuerbaren Stromerzeugung bilden die Wasser- und Solarkraft, mit zusammen über 68 %. Die Region 10 hat einen Bruttostrombedarf von ca. 4.400 GWh, ca. 50 % davon werden für die Industrie benötigt. Der Rest teilt sich auf, auf den Strombedarf für Haushalte und den Strombedarf im Bereich Gewerbe, Handel, Dienstleistungen. Die Region 10 erreichte im Jahr 2021 einen Anteil von 46 % an erneuerbaren Energien am Bruttostrombedarf und schafft damit die von der Bundesregierung für 2020 gestellten Ziele um 11 % zu überbieten.
        </p>

        <img src="~@/assets/region10_1.png" class="center mid-width"/>
        <img src="~@/assets/region10_2.png" class="center mid-width"/>
        <img src="~@/assets/region10_3.png" class="center mid-width"/>
        <img src="~@/assets/region10_4.png" class="center"/>
        <img src="~@/assets/region10_5.png" class="center"/>
        <img src="~@/assets/region10_6.png" class="center"/>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Region10Info',
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.region10-container {
  position: relative;
}

.start-section {
  background-image: url('~@/assets/region10_title.jpg');
}
</style>
