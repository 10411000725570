<template>
  <div class="header-container" @blur="closeOnBlur">
    <div class="logo">
      <img src="~@/assets/logos/thi_logo_wbh_w_transparent.png" />
    </div>
    <div class="header-links">
      <div class="nav-item" @click="$router.push('/')">
        <div class="label">Start</div>
      </div>
      <div class="nav-item" @mouseover="expandTech = true" @mouseleave="expandTech = false">
        <div class="label">
          Weitere Informationen
        </div>
        <div v-if="expandTech" class="tech-options submenu">
          <div class="sub-item" @click="$router.push('/info/photovoltaik')">Photovoltaik</div>
          <div class="sub-item" @click="$router.push('/info/windkraft')">Windkraft</div>
          <div class="sub-item" @click="$router.push('/info/wasserkraft')">Wasserkraft</div>
          <div class="sub-item" @click="$router.push('/info/biomasse')">Biomasse</div>
          <div class="sub-item" @click="$router.push('/info/mobilitaet')">E-Mobilität</div>
          <div class="sub-item" @click="$router.push('/info/waerme')">Strom im Wärmesektor</div>
          <div class="sub-item" @click="$router.push('/info/batteriespeicher')">Batteriespeicher</div>
          <div class="sub-item" @click="$router.push('/info/politische-ziele')">Politische Ziele/Energiewende</div>
          <div class="sub-item" @click="$router.push('/info/energieeffizienz')">Energieeffizenz</div>
          <div class="sub-item" @click="$router.push('/info/region10')">Region 10</div>
          <div class="sub-item" @click="$router.push('/info/weiterfuehrende-links')">Weiterführende Links</div>
        </div>
      </div>
      <div class="nav-item">
        <div class="label"><a href="https://energiewende-region-10.readthedocs.io/" target="_blank">Dokumentation</a></div>
      </div>
      <!--div class="nav-item" @click="$router.push('/')">
        <div class="label">FAQs</div>
      </div-->
      <div class="nav-item" @click="$router.push('/application')">
        <div class="label">Anwendung</div>
      </div>
      <div class="nav-item" @click="$router.push('/contact')">
        <div class="label">Kontakt</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheHeader',
  data() {
    return {
      expandTech: false,
    }
  },
  mounted() {
    window.addEventListener('click', this.closeOnBlur)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeOnBlur)
  },
  methods: {
    closeOnBlur(e) {
      if (!this.$el.contains(e.target)){
        this.expandTech = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.header-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 79px;
  background-color: rgba($dark, 0.8);
  z-index: 100;
  color: white;
  padding-right: 20px;
  border-bottom: 1px solid $highlight;

  .header-links {
    text-align: right;

    .nav-item {
      padding: 30px;
      display: inline-block;
      cursor: pointer;
      position: relative;

      .label:hover {
        font-weight: bold;
      }
    }
  }

  .logo {
    padding: 10px 30px;
    display: inline-block;
    position: relative;
    float: left;

    img {
      height: 59px;
    }
  }
}

.submenu {
  padding: 20px;
  background-color: white;
  color: $dark;
  border-bottom: 10px solid $highlight;
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  font-weight: normal;

  .sub-item {
    padding: 5px 0px;

    &:hover {
      font-weight: bold;
    }
  }
}
.submenu:after, .submenu:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}
.submenu:after {
	border-color: rgba(136, 183, 213, 0);
	border-bottom-color: white;
	border-width: 10px;
	margin-left: -10px;
}

a {
  color: inherit;
  text-decoration: inherit;
}
</style>
