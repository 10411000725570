<template>
  <div class="infomodal-container">
    <div class="trigger" @click.stop="showModal = true">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="position:relative;top:4px;"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
    </div>

    <div v-if="showModal" class="modal-overlay" @click="showModal = false">
      <div class="modal-content content" @click.stop>
        <div class="close-icon" @click="showModal = false">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" style="transform:rotate(45deg);position:relative;top:4px;"><g fill="none"><g style="stroke-width:3;stroke:#4dbcb7"><path d="M8 8L8-1 8 8 -1 8 8 8ZM8 8L17 8 8 8 8 17 8 8Z"/></g></g></svg>
        </div>
        <slot></slot>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'InfoModal',
  data() {
    return {
      showModal: false,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.infomodal-container {
  display: inline-block;
  font-size: 16px;
  color: white;
  text-transform: none;
  font-weight: normal;
}

.trigger {
  opacity: 0.5;
  transition: 0.1s opacity;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.content {
  background-color: $sidebar;
  padding: 50px;
  cursor: default;
  text-align: justify;
}

.close-icon {
  position: absolute;
  top: -16px;
  right: -16px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(black, 0.4);
  text-align: center;
  cursor: pointer;
}
</style>
