<template>
  <div class="demographics-container" @click.stop>
    <div class="not_loading" v-if="!loading">
      <div class="title">
        Daten zur statistischen Auswertung
        <info-modal>
          Durch die Eingabe Ihrer Daten helfen Sie uns die Meinung der
          Region 10 einzufangen, sodass wir diese an die Politik
          spiegeln können. Sie ermöglichen anhand Ihrer Daten
          statistische Auswertungen durchzuführen.
          <br>
          <br>
          Die detaillierte Auswertung des Energiemodells für ihre Eingabewerte
          wird sich nach Fertigstellung in einem neuen Fenster oder Tab öffnen.
        </info-modal>
      </div>
      <div class="content">
        <div class="item">
          <div class="label">
            <label class="container">
              Wir sind eine Gruppe
              <input type="checkbox" v-model="group">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="content">
        <template v-if="!group">
          <div class="item">
            <div class="label">
              Alter
            </div>
            <div class="selection">
              <dropdown-wrapper :options="ageOptions" v-model="age"/>
            </div>
          </div>
          <div class="item">
            <div class="label">
              Geschlecht
            </div>
            <div class="selection">
              <dropdown-wrapper :options="genderOptions" v-model="gender"/>
            </div>
          </div>
          <div class="item">
            <div class="label">
              Berufliches Umfeld
            </div>
            <div class="selection">
              <dropdown-wrapper :options="jobOptions" v-model="job"/>
            </div>
          </div>
          <div class="item">
            <div class="label">
              Region
            </div>
            <div class="selection">
              <dropdown-wrapper :options="regionOptions" v-model="region"/>
            </div>
          </div>
        </template>
        <div class="item">
          <div class="label">
            EMail
          </div>
          <div class="selection">
            <div class="email">
              <input class="input" v-model="email" :class="{ valid: emailValid, invalid: !emailValid }" :placeholder="group ? 'eure@email.de' : 'deine@email.de'"/>
            </div>
          </div>
        </div>

        <div class="content">
          <div class="item" v-if="group">
            <div class="label">
              Zugehörige Gruppe
            </div>
            <div class="selection">
              <dropdown-wrapper :options="associatedGroupOptions" v-model="associatedGroup"/>
            </div>
          </div>
        </div>

        <div class="consent">
          <label class="container">
            Hiermit stimme ich den
            <router-link to="/contact">Datenschutzbestimmungen</router-link>
            , sowie der Auswertung meiner komplett anonymisierten Angaben zu.
            <input type="checkbox" v-model="consent">
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="button-container">
          <div class="button" @click="submit()" :disabled="!dataValid">Auswerten</div>
        </div>
      </div>
    </div>
    <div v-if="loading">
      Einen Moment Geduld. Ihre Auswertung wird ihnen in
      wenigen Augenblicken in einem neuen Tab geöffnet. Dies
      kann bis zu 60 Sekunden dauern.
      <p>Achten Sie darauf, dass ihr Browser/Adblocker das Öffnen neuer Tabs nicht blockiert.</p>
      <p>Wussten Sie, dass in Neuburg a.d. Donau auf einem ehemaligen Kasernengelände mit
        historischen Gebäuden und altem Baumbestand ein lebendiges Wissensquartier für
        Studierende und Wissenschaftler entsteht? Im Wintersemester 21/22 starten wir
        mit zwei nachhaltigen Bachelorstudiengängen. </p>
      <p>Weitere Informationen finden Sie unter: <a class="link-name-ref" href="https://www.thi.de/"/> </p>
      <div v-show="loading" class="loading-animation">
        <div class="sk-cube-grid">
          <div class="sk-cube sk-cube1"></div>
          <div class="sk-cube sk-cube2"></div>
          <div class="sk-cube sk-cube3"></div>
          <div class="sk-cube sk-cube4"></div>
          <div class="sk-cube sk-cube5"></div>
          <div class="sk-cube sk-cube6"></div>
          <div class="sk-cube sk-cube7"></div>
          <div class="sk-cube sk-cube8"></div>
          <div class="sk-cube sk-cube9"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataService from "@/services/data-service.js"

import DropdownWrapper from '@/components/DropdownWrapper.vue'
import InfoModal from '@/components/InfoModal.vue'

export default {
  name: 'DemographicsModal',
  components: {
    InfoModal,
    DropdownWrapper,
  },
  data() {
    return {
      loading: false,
      group: false,

      age: null,
      ageOptions: ["unter 18", "18-24", "25-34", "35-49", "50-64", "65-79", "80+"],

      gender: null,
      genderOptions: ['männlich', 'weiblich', 'divers'],

      job: null,
      jobOptions: ["Industrie", "Forschung", "Handel", " Selbstständig", "Schule", "Uni/Hochschule", "Politik", "Rente", "Bürgermeister","Klimaschutzmanager", "Bauamt", "Bürgerenergiegenossenschaft", "Stadtwerke", "Klimaschutzumfeld", "Sonstiges"],

      region: null,
      regionOptions: ['Eichstätt', 'Ingolstadt', 'Pfaffenhofen', 'Neuburg - Schrobenhausen', "Sonstige"],

      email: "",

      consent: false,

      associatedGroup: null,
      associatedGroupOptions: ['Schule', 'Hochschule', 'Unternehmen', 'Politik', 'Bürgerenergiegenossenschaft', 'Verein', 'Andere'],
    }
  },
  methods: {
    async submit() {
      if(!this.dataValid) {
        return
      }
      let userData = {
        "age": this.age,
        "gender": this.gender,
        "job": this.job,
        "region": this.region,
        "email": this.email,
        "associatedGroup": 'nogroup'
      }
      if (this.group) {
        userData = {
          "age": "group",
          "gender": "group",
          "job": "group",
          "region": "group",
          "email": this.email,
          "associatedGroup": this.associatedGroup
        }
      }
      this.loading = true
      let r = await dataService.energyModelRequest(this.$store.getters.energyModelParameters, userData)

      let data = await r.json()
      this.$router.push("/application/results?token=" + data.token)

      this.loading = false
      this.$emit('close')
    }
  },
  computed: {
    dataValid() {
      return (this.age && this.gender && this.job && this.region && this.emailValid && this.consent) || (this.group && this.associatedGroup && this.emailValid && this.consent)
    },
    emailValid() {
      const emailRegex = /\S+@\S+\.\S+/
      return emailRegex.test(this.email)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

a {
  text-decoration: underline;
  color: inherit;
}

.demographics-container {
  background-color: $sidebar;
  color: white;
  min-width: 700px;
  padding: 50px;
}

.title {
  text-transform: uppercase;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 50px;
}

.content {
  .item {
    display: flex;

    height: 50px;

    .selection {
      flex: 1;
      text-align: right;
    }
  }
}

.email {
  width: 282px;
  display: inline-block;

  input {
    width: 100%;
    box-sizing: border-box;
    background-color: $sidebar;
    border-radius: 6px;
    color: white;
    outline: none;
    border: none;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid transparent;

    &:focus {
      box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.5);
    }

    &::placeholder {
      color: rgba(white, 0.2);
    }
  }

  .valid {
    border: 1px solid rgb(0, 167, 0) !important;
    border-radius: 6px;
  }

  .invalid {
    border: 1px solid rgb(182, 0, 0) !important;
    border-radius: 6px;
  }
}

.button-container {
  margin-top: 50px;
  text-align: center;
}


.consent {
  text-align: left;
  margin: 40px 0px;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  // font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: $highlight;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.loading-animation {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  z-index: 9999;
}

.sk-cube-grid {
  width: 100%;
  height: 100%;
  // margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: $highlight;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}
.link-name-ref::after {
  content: attr(href);
}

</style>
