import Vue from "vue";
import Vuex from "vuex";

import powerPlantService from "@/services/data-service.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // powerPlants: [],
    energyModelParameters: [],
    // energyAnalysis: null,

    energyData: null,
    communities: [],
    radiusRepresentation: "power",
    communityKey: "",

    loading: 0
  },
  mutations: {
    mutate(state, { prop, value }) {
      state[prop] = value;
    },
    loading(state, loading) {
      if (loading) {
        state.loading++;
      } else {
        state.loading--;
      }
    }
  },
  actions: {
    async updatePlants({ commit }, energyModelParameters) {
      commit("mutate", {
        prop: "energyModelParameters",
        value: energyModelParameters
      });
      commit("loading", true);
      let response = await powerPlantService.queryPlants(energyModelParameters);
      commit("mutate", { prop: "energyData", value: response });
      // commit("mutate", { prop: "powerPlants", value: response.power_plants })
      // commit("mutate", {
      // prop: "energyAnalysis",
      //value: response.energy_analysis
      //})
      commit("loading", false);
    },
    async getCommunities({ commit }) {
      let response = await powerPlantService.getCommunities();

      commit("mutate", {prop: "communities", value: response})
    },
    async getGeoJson({ commit }) {
      let response = await powerPlantService.getGeoJson();

      commit("mutate", {prop: "geoJson", value: response})
    }
  },
  modules: {},
  getters: {
    powerPlants: state =>
      state.energyData ? state.energyData.power_plants : [],
    energyCoverage: state =>
      state.energyData
        ? state.energyData.energy_analysis.planned_production_total /
          state.energyData.energy_demands.total
        : 0,
    batteryStorageCoverage: state =>
        state.energyData
            ? ((state.energyData.energy_analysis.installed_battery_storage +
             state.energyData.energy_analysis.planned_battery_storage)-5.3)
        : 0,

    energyAnalysis: state => state.energyData.energy_analysis,
    energyDemand: state => state.energyData.energy_demands,
    energyModelParameters: state => state.energyModelParameters,

    radiusRepresentation: state => state.radiusRepresentation,

    loading: state => state.loading,
  }
});
