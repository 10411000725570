<template>
  <div class="chartEnergyBar">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>

import Chart from 'chart.js/auto';

export default {
  name: 'ChartEnergyBar',
  props: ['chartEnergyBarAnnual', 'chartEnergyBarFeedIn', 'bars'],
  components: {
  },
  async mounted() {
    this.init()

    let arrOuter = [];
    let obj = {};
    let colorArr = ['#91AE58', '#394866', '#A3D3DE', '#FFCC99', '#F0D26C', '#DB99FF'];
    let i = 0;

    for (const [keyAnnual, valueAnnual] of Object.entries(this.chartEnergyBarAnnual)) {
        let arr = [];
        arr[0] = valueAnnual;
        arr[1] = this.chartEnergyBarFeedIn[keyAnnual];
        obj = {
          label: keyAnnual,
          data: arr,
          backgroundColor: colorArr[i]
        }

        arrOuter.push(obj);
        i++
    }

    const labels = this.bars;
    const data = {
      labels: labels,
      datasets: arrOuter
    };

    const chartEnergyBarAnnual = new Chart(this.$refs["chart"].getContext("2d"), {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            ticks: {
              callback: function(value,) {
                return value + " GWh";
              }
            }
          }
        }
      }
    });
    chartEnergyBarAnnual;

  },
  data() {
    return {
      token: null,
      results: null,
      chart: null
    }
  },
  methods: {
    init() {}
  },
}
</script>

<style lang="scss" scoped>

</style>
