<template>
  <div class="wind-container">
    <div class="start-section">
      <div class="title">
        Windkraft
      </div>
    </div>
    <div class="section">
      <div class="section-subtitle">
        Allgemein
      </div>
      <div class="content">
        <p>
          Windkraftanlagen nutzen die kinetische Energie der Luftströmung um erneuerbare, elektrische Energie zu erzeugen. Sie benötigen einen geringen Flächenbedarf und produzieren ihren Strom vorwiegend im Winter, wenn der Energiebedarf am höchsten ist. Damit bieten sie eine geeignete Ergänzung zu Photovoltaikanlagen, welche im Sommer den größten Teil Ihrer Stromerzeugung aufweisen. Die Stromerzeugung von Windkraftanlagen hängt u. a. von Parametern wie der Windgeschwindigkeit, der Nabenhöhe, dem Rotordurchmesser sowie der Verschattung (durch andere Windkraftanlagen) ab.
        </p>
      </div>
      <div class="section-subtitle">
        Zur Region
      </div>
      <div class="content">
        <p>
          Als Binnenregion herrschen in Bayern geringere Windgeschwindigkeiten
          als in Norddeutschland vor, sodass vergleichsweise weniger
          Windkraftanlagen in Bayern stehen. Zudem gilt in Bayern die
          10-H-Regelung. Für geplante Windkraftanlagen, die einen geringeren
          Abstand als das 10-fache ihrer Anlagenhöhe zu Siedlungsgebieten
          aufweisen, ist es nötig einen Bürgerentscheid im Vorfeld
          durchzuführen. Gleichzeitig bedeutet der im Vergleich zu anderen
          Bundesländern geringe Anteil von Windenergie an der erneuerbaren
          Stromerzeugung, dass in Bayern noch ein großes Potenzial zur
          Verfügung stehen würde. In der Region 10 stehen 68 Anlagen, die im
          Jahr ca. 250.000 MWh Strom erzeugen. Damit könnten bei einem
          durchschnittlichen jährlichen Stromverbrauch eines Haushalts von 3
          MWh, ca. 83.000 Haushalte mit Strom versorgt werden.
        </p>
        <img src="~@/assets/Windkraft_bestand.png" class="center"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WindkraftInfo',
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.wind-container {
  position: relative;
}

.start-section {
  background-image: url('~@/assets/wind01.jpg');
}
</style>
