<template>
  <div class="footer-container section">
    <div @click="$router.push('/contact')" class="contact">
      Kontakt
    </div>
    <div @click="$router.push('/contact')" class="imprint">
      Impressum
    </div>
    <div @click="$router.push('/contact')" class="privacy">
      Datenschutz
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheFooter',
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.footer-container {
  display: flex;

  div {
    flex: 1;
    text-align: center;
  }
}

.contact, .imprint, .privacy {
  cursor: pointer;
}

</style>
