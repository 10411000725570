<template>
  <div class="photovoltaik-container">
    <div class="start-section">
      <div class="title">
        Photovoltaik
      </div>
    </div>
    <div class="section">
      <div class="section-subtitle">
        Allgemein
      </div>
      <div class="content">
        <p>
          Photovoltaikanlagen nutzen die Energie der Sonneneinstrahlung zur Stromproduktion. Grundsätzlich wird zwischen Dachflächen- und Freiflächenanlagen unterschieden. Bei Dachflächenanlagen handelt es sich im Normalfall um private Anlagen, die dazu genutzt werden den eigenen Stromverbrauch abzudecken und überschüssigen Strom in das Stromnetz einzuspeisen. Installationen auf Gebäudedachflächen bieten den Vorteil, dass kein zusätzlicher Flächenbedarf für die Solaranlagen anfällt. Freiflächenanlagen werden vorrangig auf Seitenstreifen von Autobahnen oder Ackerflächen mit geringer Bodengüte errichtet. Eine Vielzahl von Einzelanlagen wird meist in Solarparks zusammengefasst, welche erneuerbaren Strom für eine gesamte Gemeinde erzeugen können.
        </p>
        <p>
          Stromproduktion durch Photovoltaikanlagen – als fluktuierende erneuerbare Energiequellen – ist abhängig von den vorherrschenden Wetterverhältnissen. Besonders zu Mittagsstunden und in den Sommermonaten ist mit günstigen Voraussetzungen zu rechnen. Beeinflusst wird die Stromerzeugung durch Parameter wie Sonneinstrahlung, Neigung der Anlage, Ausrichtung, sowie Verschattung.
        </p>

    </div>
    <div class="section-subtitle">
      Zur Region
    </div>
      <div class="content">
        <p>
          Bayern gehört in Deutschland zu den Gebieten mit erhöhter Sonneneinstrahlung und besitzt somit sehr günstige Voraussetzungen für die Nutzung von Solarenergie.
        </p>
        <p>
          In der Region 10 gibt es ca. 30.000 Dachanlagen und 127 Freiflächenanlagen, die im Jahr ca. 730.000 MWh Strom erzeugen. Damit könnten bilanziell bei einem durchschnittlichen jährlichen Stromverbrauch eines Haushalts von 3 MWh, ca. 240.000 Haushalte mit Strom versorgt werden.
        </p>
        <p>
          Große Solarparks stehen z. B. in den Gemeinden Adelschlag, Hohenwart und Neuburg a.d. Donau. Ein Solarpark mit 1 MW Peak-Leistung kann ca. 335 Haushalte im Jahr mit Strom versorgen.
        </p>
        <img src="~@/assets/ffpv_bestand.png" class="center"/>
        <img src="~@/assets/dachflaechen.png" class="center"/>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhotovoltaikInfo',
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.photovoltaik-container {
  position: relative;
}

.start-section {
  background-image: url('~@/assets/solar01.jpg');
}
</style>
