<template>
  <div class="chartEnergy">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>

import Chart from 'chart.js/auto';

export default {
  name: 'ChartEnergy',
  props: ['chartEnergy', 'legend', 'showChart', 'title', 'showTitle','colors'],
  watch: {
    chartEnergy: function () {
      this.init()
    }
  },
  components: {
  },
  async updated() {
    console.log("UPDATED")
  },
  async mounted() {
    this.init()
  },
  data() {
    return {
      token: null,
      results: null,
      chart: null,
    }
  },
  methods: {
    init() {
      let chartData = Object.values(this.preparedResults(this.chartEnergy));
      if(this.showChart == false) {
        chartData = "empty"
      }

      if (this.chart) {
        this.chart.destroy()
      }

      this.chart = new Chart(this.$refs["chart"].getContext("2d"), {
        type: 'pie',
        data: {
          labels: Object.keys(this.chartEnergy),
          datasets: [{
            label: 'Chart Energy',
            data: chartData,
            backgroundColor: (this.colors !== undefined) ? this.colors : [
              'rgb(145, 174, 88)',
              'rgb(7, 72, 102)',
              'rgb(163, 211, 222)',
              'rgb(255, 204, 153)',
              'rgb(240, 210, 108)',
              'rgb(219, 153, 255)',
            ],
            hoverOffset: 5
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                label: function(tooltipItem) {
                  return tooltipItem.label + ": " + Math.ceil(tooltipItem.parsed) + "%";
                }
              }
            },
            legend: {
              display: false
            },
            title: {
              display: this.showTitle,
              text: this.title,
              padding: {
                top: 10,
                bottom: 30
              },
              position: 'bottom'
            }
          }
        }
      });
      this.chart;

      window.addEventListener('resize', () => {
        //console.log("RESIZE")
        //console.log(chartEnergy)
        this.chart.resize();
      });
    },
    preparedResults(data) {
      let arr = [];
      let sumNum = 0;
      for (const key in data) {
        sumNum += data[key];
      }
      for (const [key, value] of Object.entries(data)) {
        arr[key] = (value * 100 / sumNum).toFixed(1)
      }
      return arr;
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
