<template>
  <div class="chartEnergyBars">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>

import Chart from 'chart.js/auto';

export default {
  name: 'chartEnergyBars',
  props: ['chartEnergyFeedIn',"labels","colors","customTooltipScale"],
  watch: {
    chartEnergyFeedIn: function () {
      this.init()
    }
  },
  components: {
  },
  async mounted() {
    this.init()
  },
  data() {
    return {
      token: null,
      results: null,
      chart: null
    }
  },
  methods: {
    init() {
      let arrOuter = [];
      let obj = {};
      let colorArr = this.colors ? this.colors : ['#A3D3DE', '#394866', '#91AE58', '#F0D26C'];
      let i = 0;

      for (const [keyFeedInMonth, valueFeedInMonth] of Object.entries(this.chartEnergyFeedIn)) {
        obj = {
          label: keyFeedInMonth,
          data: valueFeedInMonth,
          backgroundColor: colorArr[i]
        }

        arrOuter.push(obj)
        i++
      }

      const labels = this.labels ? this.labels : [
        'Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez',
      ];
      const data = {
        labels: labels,
        datasets: arrOuter
      };

      if (this.chart) {
        this.chart.destroy()
      }

      var customTooltipScale = this.customTooltipScale

      this.chart = new Chart(this.$refs["chart"].getContext("2d"), {
        type: 'bar',
        data: data,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: true,
              callbacks: {
                label: function(tooltipItem) {
                  console.log(tooltipItem)
                  if (customTooltipScale !== undefined) {
                    return tooltipItem.dataset.label + ": " + Math.floor(tooltipItem.parsed.y * 100) / 100 + " " + customTooltipScale;
                  } else {
                    return tooltipItem.raw
                  }
                }
              }
            }
          },
          scales: {
            y: {
              ticks: {
                callback: function(value,) {
                  return value + " GWh";
                }
              }
            }
          }
        }
      });
      this.chart;
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
