<template>
  <div class="contact-container">

    <the-header/>

    <div class="contact">

      <div class="impressum">
        <div class="title">Impressum</div>

        <pre>
<b>Technische Hochschule Ingolstadt</b>
Esplanade 10, 85049 Ingolstadt

Telefon: + 49 841 / 9348-0
Fax: + 49 841 / 9348-2000
E-Mail: info@thi.de

Die Technische Hochschule Ingolstadt ist eine Körperschaft des Öffentlichen Rechts. Sie wird durch ihren Präsidenten Prof. Dr. Walter Schober gesetzlich vertreten.

<b>Zuständige Aufsichtsbehörde:</b> Bayerisches Staatsministerium für Wissenschaft und Kunst, 80333 München

<b>Umsatzsteuer-Identifikationsnummer</b> gem. § 27 Umsatzsteuergesetz (UStG): DE 234419796

<b>Gesamtverantwortlichkeit für den Inhalt:</b> Prof. Dr. Walter Schober, Präsident

<b>Projektkoordination und Redaktion der allgemeinen Inhalte:</b> Prof. Dr. Uwe Holzhammer, Robin Tutunaru, Ann-Kathrin Roßner

<b>Design:</b> Matthias Feigel unter Betreuung von Prof. Ingrid Stahl

<b>Anregungen und Kritik:</b> menschinbewegung@thi.de
        </pre>
      </div>

      <div class="haftungsausschluss">
        <pre>
<b>Haftungsausschluss:</b>

Die Inhalte unserer Website wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte innerhalb der Website nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.

<b>Haftung für Links</b>

Mit Urteil vom 12. Mai 1998 hat das Landgericht Hamburg entschieden, dass mit Anbringung eines Verweises die Inhalte der verwiesenen Seite ggf. mit zu verantworten sind. Dies kann - so das Landgericht - ausschließlich dadurch verhindert werden, dass man sich von diesen Inhalten ausdrücklich distanziert.
Wir bemühen uns um Sorgfalt bei der Auswahl der Verweise, haben aber selbst keinerlei Einfluss auf Inhalte von gelinkten externen Seiten. Die Technische Hochschule Ingolstadt erklärt, dass sie sich ausdrücklich von allen Inhalten sämtlicher extern verlinkter Seiten distanziert. Neben Links auf externe Websites, zu denen von unserer Website verwiesen wird, distanziert sich die Technische Hochschule Ingolstadt auch von allen Inhalten, die auf eigenen Servern bereitgestellt werden (Web-Hosting). Darunter fallen insbesondere die privaten Homepages von Studierenden und Mitarbeitern der Technischen Hochschule Ingolstadt.

<b>Urheberrecht</b>

Die durch die Applikationsbetreiber erstellten Inhalte und Werke innerhalb dieser Website unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Inhalte sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte dieser Website nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </pre>
      </div>

      <div class="datenschutz">
        <div class="title">Datenschutzhinweise</div>
        <div class="subtitle">für die Website „energiewende-regionalisieren.de“ der Technischen Hochschule Ingolstadt (THI) </div>
        <pre>
Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (Europäische Datenschutzgrundverordnung - DSGVO, Bayerisches Datenschutzgesetz – BayDSG).
Entsprechend dieser Bestimmungen informieren wir Sie in diesen Datenschutzinformationen über die für Sie relevanten Aspekte der Verarbeitung Ihrer Daten auf der Website „Energiewende regionalisieren“.

<b>Name und Kontaktdaten des Verantwortlichen</b>

Technische Hochschule Ingolstadt (THI)
Esplanade 10, 85049 Ingolstadt
vertreten durch den Präsidenten Prof. Dr. Walter Schober

Tel. +49 841 9348-0
Email <a class="link" href="mailto:info@thi.de">info@thi.de</a>

<b>Kontaktdaten des Datenschutzbeauftragten</b>

Technische Hochschule Ingolstadt
Esplanade 10, 85049 Ingolstadt

Tel. +49 841 9348-1234
Email <a class="link" href="mailto:datenschutz@thi.de">datenschutz@thi.de</a>

<b>Ansprechpartner für das Projekt</b>

Prof. Dr.-Ing. Uwe Holzhammer (Projektleitung)
Telefon: + 49 841 / 9348-5025
Email <a class="link" href="mailto:Uwe.Holzhammer@thi.de">Uwe.Holzhammer@thi.de</a>

Robin Tutunaru (Wissenschaftlicher Mitarbeiter)
Telefon: + 49 841 / 9348-5016
Email <a class="link" href="mailto:Robin.Tutunaru@thi.de">Robin.Tutunaru@thi.de</a>


<b>1 Nutzung des Energiewende-Tools</b>

Mit dem Tool „Energiewende regionalisieren“ können Sie Ihre eigenen Einstellungen zur Entwicklung der Energiewende in der Region 10 tätigen. Dabei werden Ihnen Optionen zum Ausbau der Erneuerbaren Energien sowie zur Entwicklung des Strombedarfs und Einsatz von Sektorkopplung und Batteriespeichern gegeben. Die Folgen Ihrer gewählten Einstellungen werden Ihnen anhand einer Karte der Region sowie mehreren Grafiken visualisiert. Dabei sehen Sie nicht nur Ihre eigene Energiewende-Vision, sondern auch den Vergleich zur allgemeinen Meinung der Bevölkerung in der Region.
<ol>
  <li>Zweck der Verarbeitung</li>
Um das Tool nutzen können, benötigen wir von Ihnen Angaben zu
• Altersgruppe
• Geschlecht
• Berufliches Umfeld
• Regionale Verortung
• Ihre Mailadresse
um ein Meinungsbild der Bewohner/innen der Region 10 zur Energiewende zu erfassen und clustern zu können. Mit den Informationen der unterschiedliche Stakeholder soll deren Beitrag zur Gestaltung der Energiewende in der Region abgebildet werden und als Basis für einen breiten Dialog zwischen Hochschulen und verschiedenen Ziel- und Anspruchsgruppen z.B. zu möglichen Folgen der Energiewende verwendet werden. Ihre Daten werden jedoch komplett anonymisiert gespeichert – eine Rückverfolgbarkeit auf einzelne Personen, Endgeräte bzw. E-Mail-Adressen ist ausgeschlossen.
Die Verarbeitung Ihrer E-Mail-Adresse dient nur zu Verifikationszwecken, um einen Missbrauch, z.B. durch eine mehrfache Teilnahme und somit Verfälschung der Ergebnisse, zu verhindern. Eine Weitergabe, Kontaktaufnahme zu Zwecken abseits der Benutzung der Website oder gar eine Nutzung für kommerzielle Zwecke findet nicht statt.

  <li>Rechtsgrundlage</li>
Rechtsgrundlage für die Erhebung und Verarbeitung der personenbezogenen Daten für die Nutzung der Plattform ist Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO.
Sie können Ihre Einwilligung jederzeit ohne Angaben von Gründen mit Wirkung für die Zukunft widerrufen, ohne dass Ihnen daraus Nachteile entstehen. Der Widerruf ist zu richten an Email <a class="link" href="mailto:XXXXXXXXXXXXXX@thi.de">XXXXXXXXXXXXXX@thi.de</a>.
  <li>Empfänger</li>
Die Daten werden nicht an Dritte weitergegeben.
  <li>Speicherdauer</li>
Wir speichern Ihre Daten, bis Sie uns zur Löschung auffordern, Sie Ihre Einwilligung zur Verarbeitung widerrufen oder der Zweck für die Speicherung entfallen ist.
  <li>Bereitstellung vorgeschrieben oder erforderlich</li>
Die Erfassung Ihrer personenbezogenen Daten erfolgt freiwillig auf Basis Ihrer Einwilligung. Ohne die Bereitstellung Ihrer Daten führt das Tool jedoch keine individuelle Auswertung durch und wir können Ihre Daten nicht für das Meinungsbild erfassen.
</ol>

<b>2 Allgemeiner Betrieb unseres Webdienstes</b>

Wenn Sie unsere Webserver kontaktieren (beispielsweise per App oder Browser), erfassen wir automatisch Informationen allgemeiner Natur. Diese Informationen beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, Ihre IP-Adresse und ähnliches.
<ol>
  <li>Zweck der Verarbeitung</li>
Wir verarbeiten die Daten insbesondere zu folgenden Zwecken:
• Sicherstellung einer reibungslosen Nutzung und Wartung unserer Plattform.
• Gewährleistung von Netz- und Informationssicherheit. Informationen dieser Art werden von uns ggfs. statistisch ausgewertet, um unsere Plattform und die dahinterstehende Technik zu optimieren.
  <li>Rechtsgrundlage</li>
Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. e DSGVO i.V.m. Art. 6 Abs. 3 DSGVO, Art. 6 Abs. 1 BayDSG für den Betrieb unserer Plattform.
  <li>Empfänger</li>
Die THI gibt Ihre Daten nur auf gesetzlich zulässiger Grundlage oder mit Ihrem Einverständnis weiter. Darüber hinaus erfolgt keine Datenweitergabe.
  <li>Speicherdauer</li>
Ihre Daten werden gelöscht, sobald der Zweck für die Speicherung entfallen ist, spätestens jedoch nach 30 Tagen.
  <li>Bereitstellung vorgeschrieben oder erforderlich</li>
Die Bereitstellung der vorgenannten personenbezogenen Daten ist nicht gesetzlich oder vertraglich vorgeschrieben. Ohne diese Daten ist jedoch die Funktionsfähigkeit unserer Plattform nicht gewährleistet. Aus diesem Grund ist ein Widerspruch ausgeschlossen.
</ol>

<b>3 Einbindung von Carto-DB</b>

Diese Website verwendet Carto-DB, einen Kartendienst von CartoDB Inc., 307 Fifth Avenue, Floor 9, New York, NY 10016, United States of America (“Carto”). Dadurch können wir Ihnen interaktive Karten in unserer Webseite anzeigen und ermöglichen Ihnen die komfortable Nutzung der Karten-Funktion.
Durch die Nutzung der Kartenfunktion auf der Website erhält Carto die Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Zudem wird Ihre IP-Adresse an Carto weitergegeben.
Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch den Plug-in-Anbieter erhalten Sie in den Datenschutzerklärungen des Anbieters. Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre:

<a class="link" href="https://carto.com/privacy/" target="_blank">https://carto.com/privacy/</a>

Carto verarbeitet Ihre personenbezogenen Daten auch in den USA und stützt die Datenübermittlung auf die Standardvertragsklauseln der EU-Kommission.

<b>4 Matomo-Statistik</b>

Auf unseren Webseiten werden anonymisierte Daten der Besucher zur Optimierung unseres Angebots mit Hilfe des Tracking-Systems "Matomo" erfasst und gespeichert. Wir setzen hierzu eine Konfiguration ohne Cookies ein. Die Daten werden nicht dazu benutzt, die Besucher der Website persönlich zu identifizieren. Eine Weitergabe der Daten an Dritte erfolgt nicht.

<b>5 Betroffenenrechte</b>

Nach der Datenschutzgrundverordnung stehen Ihnen folgende Rechte in Bezug auf Ihre personenbezogenen Daten zu:
• Werden Ihre personenbezogenen Daten verarbeitet, so haben Sie das Recht Auskunft über die zu Ihrer Person gespeicherten Daten zu erhalten (Art. 15 DSGVO).
• Sollten unrichtige personenbezogene Daten verarbeitet werden, steht Ihnen ein Recht auf Berichtigung dieser Daten zu (Art. 16 DSGVO).
• Liegen die gesetzlichen Voraussetzungen vor, so können Sie die Löschung oder Einschränkung der Verarbeitung verlangen (Art. 17 und 18 DSGVO).
• Wenn Sie in die Datenverarbeitung eingewilligt haben oder ein Vertrag zur Datenverarbeitung besteht und die Datenverarbeitung mithilfe automatisierter Verfahren durchgeführt wird, steht Ihnen gegebenenfalls ein Recht auf Datenübertragbarkeit zu (Art. 20 DSGVO).
• Sollten Sie von Ihren oben genannten Rechten Gebrauch machen, prüft die öffentliche Stelle, ob die gesetzlichen Voraussetzungen hierfür erfüllt sind.
• Weiterhin besteht ein Beschwerderecht bei einer Aufsichtsbehörde. Die für uns zuständige Aufsichtsbehörde ist der Bayerische Landesbeauftragte für den Datenschutz, Wagmüllerstraße 18, 80538 München.

<em>Widerspruchsrecht</em>

Aus Gründen, die sich aus Ihrer besonderen Situation ergeben, können Sie der Verarbeitung Sie betreffender personenbezogener Daten durch uns jederzeit widersprechen (Art. 21 DSGVO). Sofern die gesetzlichen Voraussetzungen vorliegen, verarbeiten wir in der Folge Ihre personenbezogenen Daten nicht mehr.

<em>Widerrufsrecht</em>

Sie können eine erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen (Art. 7 Abs. 3 DSGVO). Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Datenverarbeitung wird dadurch nicht berührt.
        </pre>
      </div>

      <div class="kontakt">
        <div class="title">Kontakt</div>
        <pre>
<b>Prof. Dr.-Ing. Uwe Holzhammer (Projektleitung)</b>
Telefon: + 49 841 / 9348-5025
E-Mail: Uwe.Holzhammer@thi.de
<b>Robin Tutunaru (Wissenschaftlicher Mitarbeiter)</b>
Telefon: + 49 841 / 9348-5016
E-Mail: Robin.Tutunaru@thi.de


<b>Technische Hochschule Ingolstadt</b>
Esplanade 10, 85049 Ingolstadt
Telefon: + 49 841 / 9348-0
Fax: + 49 841 / 9348-2000
E-Mail: info@thi.de
        </pre>
      </div>

    </div>

    <partner-logos/>

     <the-footer/>

  </div>
</template>

<script>
import PartnerLogos from '@/components/PartnerLogos.vue'
import TheFooter from "@/components/TheFooter";
import TheHeader from "@/components/TheHeader";

export default {
  name: 'ContactPage',
  components: {
    TheHeader,
    TheFooter,
    PartnerLogos,
  },
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>

a {
  text-decoration: none;
  color: inherit;
}

.contact-container {
  padding-top: 100px;
}

.contact {
  text-align: left;
  padding: 50px;
  max-width: 800px;
  margin: auto;

  pre {
    white-space: pre-wrap
  }
}

.title {
  margin-top: 30px;
  font-size: 24px;
}
</style>
