import Vue from "vue"
import VueRouter from "vue-router"

import Main from "@/views/HomePage.vue"
import Info from "@/views/more-information/Info.vue"
import Contact from "@/views/ContactPage.vue"
import Application from "@/views/ApplicationContainer.vue"
import ResultsPage from "@/views/ResultsPage.vue"

import Photovoltaik from "@/views/more-information/Photovoltaik.vue"
import Wind from "@/views/more-information/Windkraft.vue"
import Wasser from "@/views/more-information/Wasserkraft.vue"
import Biomasse from "@/views/more-information/Biomasse.vue"
import Mobilitaet from "@/views/more-information/Mobilitaet.vue"
import Waerme from "@/views/more-information/Waerme.vue"
import Batteriespeicher from "@/views/more-information/Batteriespeicher.vue"
import Politics from "@/views/more-information/Politik.vue"
import Region10 from "@/views/more-information/Region10.vue"
import Energieeffizienz from "@/views/more-information/Energieeffizienz.vue"
import WeiterfuehrendeLinks from "@/views/more-information/WeiterfuehrendeLinks.vue"
import StatisticsPage from "../views/StatisticsPage";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "main",
    component: Main
  },
  {
    path: "/application",
    name: "application",
    component: Application,
  },
 {
    path: "/application/results",
    name: "results",
    component: ResultsPage
  },
  {
    path: "/application/statistics",
    name: "statistics",
    component: StatisticsPage
  },
  {
    path: "/info",
    name: "info",
    component: Info,
    children: [
      {
        path: "photovoltaik",
        name: "photovoltaik",
        component: Photovoltaik
      },
      {
        path: "windkraft",
        name: "windkraft",
        component: Wind
      },
      {
        path: "wasserkraft",
        name: "wasserkraft",
        component: Wasser
      },
      {
        path: "biomasse",
        name: "biomasse",
        component: Biomasse
      },
      {
        path: "mobilitaet",
        name: "mobilitaet",
        component: Mobilitaet
      },
      {
        path: "waerme",
        name: "waerme",
        component: Waerme
      },
      {
        path: "batteriespeicher",
        name: "batteriespeicher",
        component: Batteriespeicher
      },
      {
        path: "politische-ziele",
        name: "politische-ziele",
        component: Politics
      },
      {
        path: "energieeffizienz",
        name: "energieeffizienz",
        component: Energieeffizienz
      },
      {
        path: "region10",
        name: "region10",
        component: Region10
      },
      {
        path: "weiterfuehrende-links",
        name: "weiterfuehrende-links",
        component: WeiterfuehrendeLinks
      }
    ]
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.hash) {
        return { el: to.hash }
      } else {
        return { x: 0, y: 0 }
      }
    }
  },
  routes
})

export default router
