import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMatomo from 'vue-matomo'

Vue.config.productionTip = false

// ------------------
// LOADING ANIMATION
// ------------------
Vue.mixin({
  methods: {
    $loading(isLoading) {
      this.$store.commit("loading", isLoading)
    }
  }
});

Vue.use(VueMatomo, {
  host: "https://matomo.energiewende-regionalisieren.de",
  siteId: 1,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: true,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: []
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
