<template>
  <div class="chartEnergyBarsOwn">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>

import Chart from 'chart.js/auto';

export default {
  name: 'chartEnergyBarsOwn',
  props: ['chartEnergyAut'],
  components: {
  },
  async mounted() {
    this.init()

    let arrOuter = [];
    let obj = {};
    let colorArr = ['#84E5CD', '#84E5CD', '#88D96F', '#BDB76B','#F0E68C'];
    let i = 0;

    for (const [keyAut, valueAut] of Object.entries(this.chartEnergyAut.user)) {
      let arr = [];
      arr.push(valueAut);
      arr.push(this.chartEnergyAut.present[keyAut]);
      obj = {
        label: keyAut,
        data: arr,
        backgroundColor: colorArr[i]
      }
      arrOuter.push(obj)
      i++
    }

    const labels = [
        'Eingestelltes Szenario', 'Aktueller Stand (2021)',
    ];
    const data = {
      labels: labels,
      datasets: arrOuter
    };

    const chartEnergyBarsOwn = new Chart(this.$refs["chart"].getContext("2d"), {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          y: {
            ticks: {
              callback: function(value,) {
                return value + "%";
              }
            }
          }
        }
      }
    });
    chartEnergyBarsOwn;
  },
  data() {
    return {
      token: null,
      results: null,
      chart: null
    }
  },
  methods: {
    init() {}
  },
}
</script>

<style lang="scss" scoped>

</style>
