<template>
  <div class="waerme-container">
    <div class="start-section">
      <div class="title">
        Strom im Wärmesektor
      </div>
    </div>
    <div class="section">
      <div class="section-subtitle">
        Allgemein
      </div>
      <div class="content">
        <p>
          Dreiviertel des Gebäudebestands wurden vor 1979 und damit vor der
          1.Wärmeschutzverordnung errichtet. Diese mit Öl- oder Gasheizungen
          ausgestatteten Gebäude sind meist nicht saniert. Auf Grund dessen
          erhöht sich der Wärmebedarf und damit der Brennstoffverbrauch.
          Dementsprechend stark wirken sich beide Heizungsmodelle auf die
          Treibhausgasemissionen aus.
        </p>

        <p>
          Für das Jahr 2050 hat die Bundesregierung das Ziel erklärt im
          Gebäudebereich einen nahezu klimaneutralen Gebäudebestand zu
          erreichen. Zusätzlich zur nötigen Sanierung von älteren Gebäuden, ist
          dafür der Austausch von konventionellen Heizungssystemen durch
          elektrisch betriebene Heizungssysteme nötig. Eine Möglichkeit dafür
          ist der Einsatz von Wärmepumpen. Wärmepumpen lassen sich je nach
          Wärmequelle grob in 3 Arten unterscheiden – Luft-, Wasser- und
          Erdwärmepumpen.
        </p>

        <p>
          Wärmepumpen entziehen der jeweiligen Wärmequelle Energie. Dem
          flüssigen Kältemittel reicht diese Wärmeenergie aus, um zu
          verdampfen. Mithilfe eines elektrisch angetriebenen Verdichters wird
          dieses Gas auf das gewünschte Temperaturniveau gebracht (Wärme durch
          Kompression), um es anschließend in einem Wärmetauscher zu
          kondensieren und dem Heizkreislauf zuzuführen.  Lediglich der
          Verdichtungsprozess benötigt elektrische Energie, welche ein
          Vielfaches an Heizenergie erzeugen kann. Wird dieser Strom aus
          erneuerbaren Energien bereitgestellt, arbeitet die Wärmepumpe nahezu
          emissionsfrei. Wärmepumpen sind meistens nur dann wirtschaftlich
          einsetzbar, wenn es sich um einen Neubau handelt oder ein Altbau
          saniert wird. Weitere Parameter von denen ein effizienter Einsatz
          einer Wärmepumpe abhängt, sind u. a. die Vorlauftemperatur, die
          Jahresarbeitszahl, die Leistungszahl sowie die Wärmequelle.
        </p>
      </div>
      <div class="section-subtitle">
        Zur Region 10
      </div>
      <div class="content">
        <p>
          In der Region 10 sind derzeit ca. 7.300 Wärmepumpen im Einsatz. Insgesamt gibt es in der Region ca. 228.000 Haushalte.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaermeInfo',
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/shared.scss';

.waerme-container {
  position: relative;
}

.start-section {
  background-image: url('~@/assets/strom_waerme_title.jpg');
}
</style>
