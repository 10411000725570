<template>
  <div class="chartEnergyBarRegion">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>

import Chart from 'chart.js/auto';

export default {
  name: 'chartEnergyBarRegion',
  props: ['chartEnergyBarRegionAnnual', 'chartEnergyBarRegionFeedIn', 'bars'],
  components: {
  },
  async mounted() {
    this.init()

    let arrOuter = [];
    let obj = {};
    let colorArr = [
      '#AA7DCE',
      '#55DDE0',
      '#7DCFB6',
      '#33658A',
      '#F6AE2D',
    ];
    let i = 0;

    for (const [keyAnnual, valueAnnual] of Object.entries(this.chartEnergyBarRegionAnnual)) {
        let arr = [];
        arr[0] = valueAnnual;
        arr[1] = this.chartEnergyBarRegionFeedIn[keyAnnual];
        obj = {
          label: keyAnnual,
          data: arr,
          backgroundColor: colorArr[i]
        }

        arrOuter.push(obj);
        i++
    }

    const labels = this.bars;
    const data = {
      labels: labels,
      datasets: arrOuter
    };

    const chartEnergyBarRegionAnnual = new Chart(this.$refs["chart"].getContext("2d"), {
      type: 'bar',
      data: data,
      options: {
        plugins: {
          legend: {
            display: false
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            ticks: {
              callback: function(value,) {
                return value + " GWh";
              }
            }
          }
        }
      }
    });
    chartEnergyBarRegionAnnual;
  },
  data() {
    return {
      token: null,
      results: null,
      chart: null
    }
  },
  methods: {
    init() {}
  },
}
</script>

<style lang="scss" scoped>

</style>
